<template>
  <!-- 로딩중 -->
  <Loading v-if="showLoading" />
  <!-- 게시글 있을 경우 -->
  <v-row v-else-if="postList.length > 0" class="cr-post-wrapper ma-0">
    <v-col class="mb-5" cols="12" v-for="post in postList" :key="post.id">
      <Post :post="post" :type="'list'" />
    </v-col>
  </v-row>
  <!-- 게시글 없을 경우 -->
  <NoData v-else></NoData>
</template>

<script>
import Post from "@/board/views/components/main/post";
import NoData from "@/board/views/components/main/list/common/NoData";
import Loading from "@/board/views/components/main/list/common/Loading";

export default {
  components: { Post, NoData, Loading },
  props: {
    posts: {
      description: "게시글 리스트"
    }
  },
  mounted() {
    this.showLoading = this.loading;
  },
  data: () => ({
    showLoading: true
  }),
  watch: {
    // 로딩 해제시 레이턴시 적용
    loading(n) {
      if (n) {
        if (this?.postList?.length == 0) this.showLoading = n;
        return;
      }
      setTimeout(() => {
        this.showLoading = n;
      }, 200);
    }
  },
  computed: {
    loading() {
      return this.posts?.loading ?? false;
    },
    postList() {
      return this.posts?.list ?? [];
    }
  }
};
</script>

<style lang="scss" scoped>
.cr-post-wrapper ::v-deep {
  position: relative;
  width: 100%;
  .v-card--link:focus:before {
    opacity: 0;
  }
  .theme--dark.v-btn:focus::before {
    opacity: 0;
  }
}
</style>
