<template>
  <div class="cr-search-list-wrapper d-flex justify-center pa-5">
    <v-row style="max-width:1100px; width:100%;">
      <v-col cols="12">
        <BoardSearchAll v-if="module === 'all'" />
        <BoardSearchModule v-else :type="module" />
      </v-col>
    </v-row>
  </div>
</template>

<style lang="scss" scoped>
.cr-search-list-wrapper ::v-deep {
  height: 100%;
  overflow-y: auto;
}
</style>

<script>
import { mapMutations } from "vuex";
import BoardSearchAll from "./BoardSearchAll";
import BoardSearchModule from "./BoardSearchModule";

export default {
  components: { BoardSearchAll, BoardSearchModule },
  async mounted() {},
  data: () => ({}),
  watch: {},
  computed: {
    // 통합검색 or 모듈별 검색
    module() {
      const { type } = this.$route.params;
      switch (type) {
        case "post":
          return "post";
        case "reply":
          return "reply";
        case "file":
          return "file";
        default:
          return "all";
      }
    }
  },
  methods: {
    ...mapMutations("boardSearch", ["INIT_SEARCH"])
  },
  destroyed() {
    this.INIT_SEARCH();
  }
};
</script>
