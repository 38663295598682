<template>
  <div>
    <v-row>
      <v-col cols="12" class="pt-8">
        <SearchPanel type="post" :listData="getPostSearchList" />
        <SearchPanel type="reply" :listData="getReplySearchList" />
        <SearchPanel type="file" :listData="getFileSearchList" />
      </v-col>
    </v-row>
  </div>
</template>

<style lang="scss" scoped></style>

<script>
import SearchPanel from "./panel/SearchPanel";
import { mapGetters } from "vuex";

export default {
  components: { SearchPanel },
  mounted() {},
  data: () => ({}),
  computed: {
    ...mapGetters("boardSearch", [
      "getSearchParam",
      "getPostSearchList",
      "getReplySearchList",
      "getFileSearchList"
    ]),
    getSearchKeyword() {
      return this.getSearchParam?.query.keyword ?? "";
    }
  },
  methods: {}
};
</script>
