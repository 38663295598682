<template>
  <div class="cr-config-wrapper pa-2">
    <v-row class="no-gutters mt-2 px-3">
      <v-col class="d-flex align-center mb-4" cols="12">
        <div class="cr-config-title" v-text="$t('board.72')"></div>
        <v-spacer />
      </v-col>
      <v-col class="cr-hideBoard-box" cols="12">
        <div
          class="cr-title-wrapper px-4 py-4 font-weight-bold d-flex align-center text-subtitle-2"
        >
          <span
            class="mr-2 grey--text text--darken-1"
            v-text="$t('board.73')"
          ></span>
          <span class="blue--text">{{ getHideBoardList.length }}</span>
        </div>
        <v-list v-if="getHideBoardList.length > 0" class="py-0 cr-list-wrapper">
          <v-list-item
            dense
            class="px-4"
            v-for="(board, index) in getHideBoardList"
            :key="index"
          >
            <v-list-item-icon class="my-0">
              <v-icon v-text="'mdi-folder-outline'"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                class="text-subtitle-1"
                v-text="board.name"
              ></v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <div>
                <v-btn
                  small
                  plain
                  text
                  :ripple="false"
                  color="red"
                  v-text="$t('board.해제')"
                  @click="hideCancel(board.id)"
                />
              </div>
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <v-list
          v-else
          class="py-0 cr-list-wrapper d-flex align-center justify-center"
        >
          <div class="grey--text" v-text="$t('board.74')"></div>
        </v-list>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  async mounted() {
    await this.loadHideBoards();
  },
  computed: {
    ...mapGetters("boardConfig", ["getHideBoardList"])
  },
  methods: {
    ...mapActions("snackbar", ["openSnackbar"]),
    ...mapActions("boardConfig", ["loadHideBoards", "hideCancel"])
  }
};
</script>

<style lang="scss" scoped>
.cr-config-wrapper ::v-deep {
  .cr-config-title {
    font-size: 1rem !important;
    font-weight: bold;
  }
  .cr-hideBoard-box {
    .cr-title-wrapper {
      border: 1px solid rgba(0, 0, 0, 0.12);
      border-bottom: none;
    }
    .cr-list-wrapper {
      height: 250px;
      overflow-y: auto;
      border: 1px solid rgba(0, 0, 0, 0.12);
      .v-list-item--dense {
        height: 50px;
        border-bottom: 1px solid #f5f5f6;

        > .v-list-item__icon:first-child {
          height: 50px;
          margin-right: 10px;
        }
        > .v-list-item__action {
          margin: 11px 0;
          margin-left: 16px;
        }
      }
    }
  }
}
</style>
