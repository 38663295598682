<template>
  <v-row class="ma-0 search-detail-wrapper">
    <!-- 게시판 선택 -->
    <v-col cols="3">
      <div class="cr-title text-body-2" v-text="'게시판'" />
      <InputSelector
        label="전체"
        :height="height"
        :initItems="boardItems"
        :selectedItem.sync="selectedBoard"
      />
    </v-col>
    <!-- 검색대상 선택 -->
    <v-col cols="3" class="d-flex align-center">
      <div>
        <div class="cr-title text-body-2" v-text="'대상'" />
        <v-select
          v-model="searchType"
          :items="searchTypeList"
          :height="height"
          dense
          outlined
          hide-details
        />
      </div>
    </v-col>
    <!-- 검색어 -->
    <v-col cols="6">
      <div style="width:100%">
        <div class="cr-title text-body-2" v-text="'검색어'" />
        <v-text-field
          ref="keywordInput"
          dense
          outlined
          :height="height"
          hide-details
          autocomplete="off"
          tabindex="1"
          placeholder="입력해 주세요"
          v-model="keyword"
          maxlength="50"
          @keydown="inputKeydown"
        ></v-text-field>
      </div>
    </v-col>
    <!-- 태그 -->
    <v-col cols="6">
      <div class="cr-title text-body-2" v-text="'태그'" />
      <div class="cr-tag-wrapper">
        <TagAutocomplete
          outlined
          fixedHeight
          :creatable="false"
          :placeholder="'태그입력'"
          :listHeight="240"
          :myTags="getTags"
          :viewTags="tags"
          @add:tag="addTag"
          @delete:tag="deleteTag"
        />
      </div>
    </v-col>
    <!-- 부가 옵션 -->
    <v-col cols="4">
      <div class="cr-title text-body-2" v-text="'포함'" />
      <div class="d-flex align-center" style="height:40px;">
        <v-checkbox
          v-model="isImportant"
          dense
          label="중요"
          color="#6A8FFF"
          hide-details
          class="ma-0 pa-0 mr-4"
        ></v-checkbox>
        <v-checkbox
          v-model="isNotice"
          dense
          label="공지사항"
          color="#6A8FFF"
          hide-details
          class="ma-0 pa-0 mr-4"
        ></v-checkbox>
        <v-checkbox
          v-model="hasAttach"
          dense
          label="첨부파일"
          color="#6A8FFF"
          class="ma-0 pa-0"
          hide-details
        ></v-checkbox>
      </div>
    </v-col>
    <v-col cols="2" class="d-flex justify-end align-end">
      <v-btn color="primary" @click="onSearch">검색</v-btn>
    </v-col>
  </v-row>
</template>

<style lang="scss" scoped>
.search-detail-wrapper::v-deep {
  background: white;
  border: thin solid rgba(0, 0, 0, 0.12);
  .cr-title {
    color: grey;
    margin-bottom: 4px;
  }
  .cr-tag-wrapper {
    .v-chip-group .v-chip {
      margin: 0px;
    }
  }
}
</style>
<script>
import InputSelector from "@/board/views/components/common/InputSelector";
import TagAutocomplete from "@/commons/views/tag/autocomplete";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { InputSelector, TagAutocomplete },
  props: {},
  mounted() {
    // 검색조건 동기화
    this.syncSearchParam();
  },
  data() {
    return {
      selectedBoard: {},
      searchTypeList: [
        {
          text: "전체",
          value: "ALL"
        },
        {
          text: "제목",
          value: "TITLE"
        },
        {
          text: "내용",
          value: "CONTENT"
        },
        {
          text: "제목+내용",
          value: "TITLE_CONTENT"
        },
        {
          text: "작성자",
          value: "WRITER"
        }
      ],
      searchType: "TITLE",
      keyword: "",
      isImportant: false,
      isNotice: false,
      hasAttach: false,
      tags: [],
      height: 40
    };
  },
  watch: {
    getSearchParam() {
      this.syncSearchParam();
    }
  },
  computed: {
    ...mapGetters("board", ["getBoards"]),
    ...mapGetters("boardSearch", ["getSearchParam"]),
    ...mapGetters("boardConfig", ["getTags"]),
    boardItems() {
      let boards = [];

      // 1 depth 구조로 변경
      this.getBoards.forEach(b => {
        const { children, ...board } = b;
        boards = children
          ? [...boards, board, ...children]
          : [...boards, board];
      });

      // 검색 가능한 게시판 필터링
      return boards.filter(b => !b.isDeleted && b.boardType == "BOARD");
    }
  },
  methods: {
    ...mapActions("snackbar", ["openSnackbar"]),
    init() {
      this.selectedBoard = {};
      this.searchType = "ALL";
      this.keyword = "";
      this.isImportant = false;
      this.isNotice = false;
      this.hasAttach = false;
      this.tags = [];
    },
    addTag(tag) {
      this.tags.push(tag);
    },
    deleteTag(tag) {
      this.tags = this.tags.filter(t => t !== tag);
    },
    inputKeydown(e) {
      if (e.key === "Enter") this.onSearch();
    },
    onSearch() {
      let queryObj = {};
      // 게시판
      if (this.selectedBoard) {
        queryObj.boardId = this.selectedBoard.id;
      }
      // 유효성 검증 및 검색라우팅
      if (this.validation()) {
        // 키워드, 대상
        queryObj.keyword = this.keyword;
        queryObj.searchType = this.searchType;
        queryObj.tags = this.tags;
        // 부가 옵션
        if (this.isImportant) queryObj.isImportant = true;
        if (this.isNotice) queryObj.isNotice = true;
        if (this.hasAttach) queryObj.hasAttach = true;

        this.$router.push({
          name: "board_search",
          params: {
            type: "post",
            query: JSON.stringify(queryObj)
          }
        });
      }
    },
    validation() {
      // 앞뒤 공백 제거
      this.keyword = this.keyword.trim();

      // 검색 입력창 Element
      const keywordInput = this.$refs?.keywordInput;

      // 글자 수 검증
      if (this.tags.length == 0 && this.keyword.length < 1) {
        this.openSnackbar({
          message: "최소 한 글자 이상 입력해야 합니다.",
          type: "VALIDATION"
        });
        keywordInput.focus();
        return false;
      }
      if (this.keyword.length > 50) {
        this.openSnackbar({
          message: "최대 50 글자까지 입력 가능합니다.",
          type: "VALIDATION"
        });
        keywordInput.focus();
        return false;
      }
      return true;
    },
    // 검색 조건 최신화
    syncSearchParam() {
      const {
        boardId = "0",
        searchType = "ALL",
        keyword = "",
        isImportant = false,
        isNotice = false,
        hasAttach = false,
        tags = []
      } = this.getSearchParam.query;

      const [selectedBoard = {}] = this.boardItems.filter(b => b.id == boardId);
      this.selectedBoard = selectedBoard;
      this.searchType = searchType;
      this.keyword = keyword;
      this.isImportant = isImportant;
      this.isNotice = isNotice;
      this.hasAttach = hasAttach;
      this.tags = [...tags];

      this.selectedBoard;
    }
  },
  destroyed() {
    this.init();
  }
};
</script>
