<template>
  <div
    v-if="showLoading"
    class="d-flex align-center justify-center grey--text"
    style="min-height:150px"
  >
    불러오는중
  </div>
  <v-row v-else class="cr-board-info ma-0">
    <v-col cols="12" class="pa-0">
      <!-- 게시판명 -->
      <div class="d-flex align-top mb-2">
        <span v-text="'게시판명 :'" class="cr-title" />
        <span v-text="board.name" class="font-weight-bold" />
      </div>
      <!-- 설명 -->
      <div class="d-flex align-top mb-2">
        <span v-text="'설명 :'" class="cr-title" />
        <span
          v-text="
            board.description
              ? board.description
              : '설명이 등록되지 않았습니다.'
          "
          style="line-height:1rem;"
        />
      </div>
      <!-- 공개 여부 -->
      <div class="d-flex align-center">
        <span v-text="'공개여부 :'" class="cr-title" />
        <span
          v-text="board.memberType === 'ANY' ? '전체공개' : '멤버공개'"
          class="font-weight-bold blue--text mr-1"
        />
        <v-icon
          v-if="board.memberType === 'MEMBER'"
          color="blue"
          size="16"
          style="margin-bottom: 3px;"
        >
          mdi-lock
        </v-icon>
      </div>
    </v-col>
    <v-col cols="12" class="px-0 py-4">
      <v-divider />
    </v-col>
    <!-- 관리자 -->
    <v-col cols="12" class="pa-0 mb-5">
      <div class="d-flex flex-column">
        <span v-text="'관리자'" class="cr-title mb-1" />
        <!-- 관리자 목록 -->
        <v-row class="ma-0">
          <v-col
            cols="12"
            v-for="item in adminList"
            :key="item.memberId"
            class="col-sm-6 pa-1 "
          >
            <!-- 프로필 아이콘-->
            <div class="d-flex align-center cr-profile">
              <v-list-item-avatar size="35" class="cr-avatar ma-0 mr-3">
                {{ avatar(item.memberName) }}
              </v-list-item-avatar>
              <span class="text-body-1 mr-auto">{{ item.memberName }}</span>
              <v-icon
                v-if="board.userId === item.memberId"
                color="yellow accent-4"
              >
                mdi-crown
              </v-icon>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-col>
    <!-- 멤버 -->
    <v-col cols="12" class="pa-0">
      <div class="d-flex flex-column">
        <span v-text="'구성원'" class="cr-title mb-1" />
        <!-- 멤버 목록 -->
        <v-row v-if="memberList.length > 0" class="ma-0">
          <v-col
            cols="12"
            v-for="item in memberList"
            :key="item.memberId"
            class="col-sm-6 pa-1 "
          >
            <!-- 프로필 아이콘-->
            <div class="d-flex align-center cr-profile">
              <v-list-item-avatar size="35" class="cr-avatar ma-0 mr-3">
                {{ avatar(item.memberName) }}
              </v-list-item-avatar>
              <span class="text-body-1 mr-auto">{{ item.memberName }}</span>
              <span class="text-caption grey--text">
                {{ getPrivilegeType(item.privilegeType) }}
              </span>
            </div>
          </v-col>
        </v-row>
        <div v-else class="d-flex justify-center cr-profile mx-1">
          <span class="grey--text">{{
            board.memberType === "MEMBER"
              ? "등록된 구성원이 없습니다."
              : "모든 사용자가 글을 쓰고 읽을 수 있습니다."
          }}</span>
        </div>
      </div>
    </v-col>
  </v-row>
</template>
<style lang="scss" scoped>
.cr-profile {
  padding: 12px;
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.05);
  color: black;
}
.cr-avatar {
  line-height: 35px;
  width: 35px;
  height: 35px;
  background: #90a4ae;
  color: white;
}
</style>
<script>
import { getBoardDetail } from "@/board/api/board.api";
import { mapActions, mapGetters, mapMutations } from "vuex";
import i18n from "@/_locales";

export default {
  props: {
    left: {
      default: false,
      type: Boolean,
      description: "좌측 버튼"
    },
    leftDisabled: {
      default: false,
      type: Boolean,
      description: "좌측 버튼 Disabled 여부"
    }
  },
  components: {},
  data: () => ({
    showLoading: true,
    board: {},
    privilege: {}
  }),
  async mounted() {
    // 편집버튼 비활성화
    this.$emit("update:leftDisabled", true);

    this.showLoading = true;

    const { id, userPrivilege } = this.getParams?.board ?? {};
    const { status, data } = await getBoardDetail(id);

    if (status === 200) {
      if (userPrivilege === "ADMIN") this.$emit("update:leftDisabled", false);
      const { board, privilege } = data;
      this.board = board;
      this.privilege = privilege;
    } else {
      this.openSnackbar({
        message: "게시판 정보를 불러오는데 실패했습니다.",
        type: "ERROR"
      });
      this.CLOSE_DIALOG();
    }

    this.showLoading = false;
  },
  watch: {
    // 편집 버튼 클릭시
    left(n) {
      if (n) {
        const title = i18n.t("board.106");
        const type = "editBoard";
        const params = { board: this.board };
        this.SET_DIALOG({ title, type, params });
      }
    }
  },
  computed: {
    ...mapGetters("boardDialog", ["getParams"]),
    adminList() {
      return this.privilege?.admins ?? [];
    },
    memberList() {
      return this.privilege?.members ?? [];
    }
  },
  methods: {
    ...mapMutations("boardDialog", ["SET_DIALOG", "CLOSE_DIALOG"]),
    ...mapActions("snackbar", ["openSnackbar"]),
    avatar(userName) {
      return userName.slice(0, 1).toUpperCase();
    },
    getPrivilegeType(privilegeType) {
      switch (privilegeType) {
        case "READ":
          return "읽기";
        case "READ_WRITE":
          return "쓰기";
        case "ADMIN":
          return "관리자";
        default:
          return "";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.cr-board-info {
  .cr-title {
    white-space: nowrap;
    min-width: 150px;
  }
}
</style>
