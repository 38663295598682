<template>
  <v-dialog
    width="500"
    persistent
    no-click-animation
    v-model="showAddLinkDialog"
  >
    <v-card>
      <v-card-title class="py-4">
        <span class="text-h6 font-weight-bold" v-text="$t('board.138')"></span>
      </v-card-title>
      <v-card-text class="py-0">
        <!-- 링크 입력창 -->
        <v-text-field
          ref="inputLink"
          class="mb-1"
          required
          autofocus
          autocomplete="off"
          :label="$t('board.139')"
          :error-messages="errorMsg"
          v-model="inputLink"
          @paste="onPaste"
          @keydown="onKeydown"
        />
        <!-- 링크 미리보기 -->
        <LinkPreview v-if="showPreview" :sharedLink="sharedLink" />
        <NoData
          :loading="loading"
          :link="inputLink"
          v-else
          @showPreview="showPreviewEvt()"
        />
      </v-card-text>
      <v-card-actions class="py-4 px-6 d-flex justify-center">
        <v-spacer />
        <v-btn text outlined @click="$emit('update:showAddLinkDialog', false)">
          {{ $t("common.취소") }}
        </v-btn>
        <v-btn outlined color="accent" @click="save" :disabled="!validUrl">
          {{ $t("common.확인") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import LinkPreview from "@/board/views/components/main/post/content/link/LinkPreview";
import NoData from "@/board/views/components/main/post/content/link/NoData";
import { isUrl } from "@/commons/utils/validation";
import { getOgData } from "@/board/api/board.api";
import i18n from "@/_locales";

export default {
  components: { LinkPreview, NoData },
  props: {
    showAddLinkDialog: {
      type: Boolean,
      default: false
    },
    link: {
      type: String,
      default: ""
    }
  },
  data: () => ({
    // 미리보기 로딩중인지 여부
    loading: false,
    // 미리보기 활성화 상태
    showPreview: false,
    inputLink: "",
    sharedLink: {},
    errorMsg: ""
  }),
  mounted() {
    // 수정일 경우 링크를 넣어준다
    this.inputLink = this.link;
  },
  destroyed() {
    this.inputLink = "";
  },
  watch: {
    inputLink(n, p) {
      // 미리보기가 표시된 상태에서 URL이 변경될 경우
      if (!p) return;
      if (this.showPreview || this.loading) {
        this.showPreview = false;
        this.loading = false;
      }
    }
  },
  computed: {
    validUrl() {
      return isUrl(this.fixedLink);
    },
    fixedLink() {
      return this.addHttp(this.inputLink);
    }
  },
  methods: {
    async getOgData(url) {
      this.loading = true;
      const { status, data } = await getOgData({
        link: url
      });
      if (status == 200 && this.loading) {
        this.sharedLink = data;
        this.showPreview = true;
        this.loading = false;
      } else {
        this.showPreview = false;
        this.loading = false;
      }
    },
    showPreviewEvt() {
      if (!this.validUrl) {
        const inputEl = this.$refs.inputLink;
        inputEl.focus();
        this.errorMsg = i18n.t("board.140");
        return;
      }
      this.getOgData(this.fixedLink);
    },
    onPaste(e) {
      // 붙여넣은 URL (inputLink 값 변경 이전에 onPaste 이벤트가 발생함)
      const url = this.addHttp(e.clipboardData.getData("text"));
      // Url 형식이 아닐경우 or 같은 링크를 붙여넣은 경우 스킵
      if (!isUrl(url)) return;
      if ((this.showPreview || this.loading) && this.fixedLink === url) return;
      this.getOgData(url);
    },
    onKeydown() {
      this.errorMsg = "";
    },
    // 프로토콜이 생략되었을 경우 붙여줌
    addHttp(url) {
      if (url.startsWith("http://") || url.startsWith("https://")) {
        return url;
      }
      return "https://" + url;
    },
    save() {
      this.$emit("update:link", this.fixedLink);
      this.$emit("update:showAddLinkDialog", false);
    }
  }
};
</script>
