<template>
  <div class="cr-view-main">
    <v-row class="cr-view-row ma-0 mx-auto">
      <v-col cols="12" class="mt-5">
        <!-- 목록 이동 버튼 -->
        <v-btn class="px-0" plain @click="goBoardList">
          <v-icon>mdi-chevron-left</v-icon>
          {{ $t("board.101") }}
        </v-btn>
      </v-col>
      <v-col cols="12" class="mb-5">
        <Post v-if="post" :post="post" :type="'view'"></Post>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { virtualBoardConverter } from "@/board/constant/boardType";
import Post from "@/board/views/components/main/post/";
export default {
  components: { Post },
  methods: {
    // 목록으로 이동
    goBoardList() {
      this.$router.push({
        name: "board_list_action",
        params: {
          boardId: virtualBoardConverter(this.getRouteListInfo.id)
        }
      });
    }
  },
  computed: {
    ...mapGetters("auth", ["getUserInfo"]),
    ...mapGetters("boardRoute", ["getRouteListInfo", "getRoutePostViewId"]),
    ...mapGetters("boardPost", ["getPostById"]),
    post() {
      return this.getPostById(this.getRoutePostViewId);
    }
  }
};
</script>

<style lang="scss" scoped>
.cr-view-main ::v-deep {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  .cr-view-row {
    max-width: 800px;
  }
}
</style>
