<template>
  <!-- 로딩중 -->
  <div v-if="loading" class="d-flex justify-center">
    <v-progress-circular :size="50" :width="4" color="grey" indeterminate />
  </div>
  <v-list v-else-if="list.length > 0" class="cr-search-list pa-0" outlined>
    <v-list-item
      v-for="(item, index) in list"
      :key="item.file.id"
      class="d-block pa-0"
    >
      <v-list-item-content
        class="cr-list-element px-4 py-3"
        @click.stop="downLoadFile(item.file.id)"
      >
        <div class="d-flex overflow-hidden">
          <v-icon
            class="file_icon mr-2"
            :size="35"
            :color="fileIconColors(fileExt(item.file))"
          >
            {{ fileIcons(fileExt(item.file)) }}
          </v-icon>
          <div class="overflow-hidden">
            <!-- 파일명(용량) -->
            <div
              class="text-subtitle-2 d-flex flex-row-reverse align-center justify-end"
            >
              <span class="font-weight-regular grey--text text-no-wrap">
                ({{ byteCalculation(item.file.size) }})
              </span>
              <div
                class="file_name mr-1 text-truncate"
                v-html="item.file.name"
              ></div>
            </div>
            <!-- 상세 -->
            <div class="d-flex text-caption">
              <UserName
                :userId="item.file.userId"
                :userName="item.file.userName"
                :userEmail="item.file.userEmail"
                class="text-no-wrap"
              />
              <v-icon size="15" style="margin:0 1px;">mdi-chevron-right</v-icon>
              <v-icon size="15" style="margin-right: 2px">
                {{ getUploadTypeIcon(item.uploadType) }}
              </v-icon>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-text="convertReplyPlainText(item.content)"
                    v-on="on"
                    v-bind="attrs"
                    class="target_content"
                    @click.stop="targetContentOpen(item)"
                  />
                </template>
                {{ item.uploadType === "POST" ? "게시글 보기" : "댓글 보기" }}
              </v-tooltip>
            </div>
          </div>
        </div>
      </v-list-item-content>
      <v-divider v-if="index < list.length - 1" :key="index" class="mx-4" />
    </v-list-item>
  </v-list>
  <!-- NO DATA -->
  <div
    v-else
    class="text-subtitle-2 grey--text text--darken-1"
    v-text="'검색 결과가 없습니다.'"
  />
</template>

<style lang="scss" scoped>
.cr-search-list::v-deep {
  .cr-list-element:hover {
    background: rgb(106 143 255 / 10%);
    cursor: pointer;
  }
  .cr-hlt {
    background: beige;
    color: black;
    font-style: normal;
  }
  .cr-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .target_content {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    &:hover {
      text-decoration: underline;
    }
  }
}
</style>

<script>
import storage from "@/commons/api/storage";
import { byteCalculation } from "@/commons/utils/byteCalculation";
import { fileIcons, fileIconColors } from "@/commons/utils/icons";
import { mapActions, mapMutations } from "vuex";
import UserName from "@/board/views/components/common/item/UserName";
export default {
  components: { UserName },
  props: {
    listData: {
      type: Object,
      default: () => {},
      description: "게시글 목록과 데이타"
    }
  },
  mounted() {},
  data: () => ({}),
  watch: {},
  computed: {
    list() {
      return this.listData.list;
    },
    loading() {
      return this.listData.loading;
    }
  },
  methods: {
    ...mapActions("boardPost", ["loadPost"]),
    ...mapMutations("boardDialog", ["SET_DIALOG"]),
    // 파일 사이즈 포맷
    byteCalculation(size) {
      return byteCalculation(size);
    },
    /** 상태 값 */
    fileIcons(ext) {
      return fileIcons[ext] || fileIcons["file"];
    },
    fileIconColors(ext) {
      return fileIconColors[ext] || fileIconColors["file"];
    },
    // 파일 확장자
    fileExt(item) {
      const fileName = this.removeHighlight(item.name);
      const index = fileName.lastIndexOf(".");
      return fileName.substring(index + 1, fileName.length).toLowerCase();
    },
    getUploadTypeIcon(uploadType) {
      if (uploadType === "REPLY") {
        return "mdi-message-reply-outline";
      } else {
        return "mdi-text-box-outline";
      }
    },
    convertReplyPlainText(replyContent) {
      return replyContent.split("<br>").join("\n");
    },
    // 하이라이트 태그 제거
    removeHighlight(str) {
      let dom = document.createElement("div");
      dom.innerHTML = str;
      return dom.innerText;
    },
    // 파일 다운로드
    downLoadFile(fileId) {
      const token = storage.getAccessToken();
      window.open(
        `${process.env.VUE_APP_API_SERVER_URL}/api/board/file/download/${fileId}?&access_token=${token}`
      );
    },
    targetContentOpen({ pid: postId, rid: replyId }) {
      // 파라미터 세팅 (댓글이 있다면 replyId 추가)
      const params = { postId };
      if (replyId) params.replyId = replyId;
      // 다이얼로그 호출
      this.SET_DIALOG({
        type: "viewPost",
        params
      });
    }
  }
};
</script>
