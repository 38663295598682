<template>
  <v-row class="ma-0 pa-0">
    <v-col cols="12" class="pa-0">
      <p>이동할 게시판을 선택하세요</p>
    </v-col>
    <v-col cols="12" class="pa-0">
      <InputSelector
        ref="refInputSelector"
        :label="$t('board.64')"
        :initItems="boards"
        :selectedItem.sync="selectBoard"
      >
      </InputSelector>
    </v-col>
  </v-row>
</template>
<script>
import InputSelector from "@/board/views/components/common/InputSelector";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { InputSelector },
  props: {
    confirm: {
      default: false,
      type: Boolean,
      description: "확인 버튼"
    },
    confirmDisabled: {
      default: false,
      type: Boolean,
      description: "확인 버튼 Disabled 여부"
    }
  },
  mounted() {
    // 게시판 SELECT 세팅
    const boards = this.getBoards.map(board => ({ ...board }));
    boards.forEach(b => {
      if (b.children) {
        const children = b.children;
        delete b.children;
        this.boards = [...this.boards, b, ...children];
      } else {
        this.boards = [...this.boards, b];
      }
    });

    // 쓰기 권한이 없는 게시판 필터링
    this.boards = this.boards.filter(
      b =>
        b.id > 0 &&
        (b.userPrivilege == "ADMIN" || b.userPrivilege == "READ_WRITE") &&
        !b.isDeleted
    );

    // 확인버튼 비활성화 여부 처리
    const exist = this.selectBoard?.id > 0;
    this.$emit("update:confirmDisabled", !exist);
  },
  data: () => ({
    boards: [],
    selectBoard: {}
  }),
  watch: {
    confirm(value) {
      if (value) {
        this.move();
        this.$emit("update:confirm", false);
      }
    },
    selectBoard(board) {
      const exist = board?.id > 0;
      this.$emit("update:confirmDisabled", !exist);
    }
  },
  methods: {
    ...mapActions("boardTrash", ["moveSelectedPost"]),
    move() {
      this.moveSelectedPost(this.selectBoard.id);
    }
  },
  computed: {
    ...mapGetters("board", ["getBoards"])
  },
  destroyed() {}
};
</script>

<style lang="scss" scoped></style>
