<template>
  <v-col class="cr-file-list-wrapper" cols="12">
    <v-data-table
      dense
      fixed-header
      hide-default-footer
      item-key="key"
      :mobile-breakpoint="0"
      :items-per-page="100"
      :headers="headers"
      :items="getItems"
    >
      <template v-slot:[`item.deleteBtn`]="{ item }">
        <v-icon small @click="DELETE_FILE(item.key)">
          mdi-close
        </v-icon>
      </template>
      <template v-slot:[`item.name`]="{ item }">
        <div>
          {{ item.name }}
        </div>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <div class="text-caption" :class="getStatusColor(item.status)">
          {{ getStatusName(item.status) }}
        </div>
      </template>
      <template v-slot:[`item.size`]="{ item }">
        <span class="grey--text">
          {{ byteCalculation(item.size) }}
        </span>
      </template>
      <template v-slot:no-data>
        <div class="d-flex no-data align-center justify-center">
          <div>
            <v-icon color="grey" size="25" class="mb-2"
              >mdi-plus-box-multiple</v-icon
            >
            <div v-text="$t('board.37')"></div>
          </div>
        </div>
      </template>
    </v-data-table>
  </v-col>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import { byteCalculation } from "@/commons/utils/byteCalculation";
import i18n from "@/_locales";

export default {
  data: () => ({
    headers: [
      {
        text: "",
        sortable: false,
        width: "28px",
        value: "deleteBtn",
        class: "deleteBtn"
      },
      {
        text: i18n.t("board.파일명"),
        sortable: false,
        value: "name"
      },
      {
        text: i18n.t("board.업로드상태"),
        sortable: false,
        value: "status",
        width: "100px"
      },
      {
        text: i18n.t("board.용량"),
        sortable: false,
        value: "size",
        width: "100px"
      }
    ]
  }),
  computed: {
    ...mapGetters("boardFile", ["getItems"])
  },
  methods: {
    ...mapMutations("boardFile", ["SET_SELECTED_ITEMS", "DELETE_FILE"]),
    byteCalculation(size) {
      return byteCalculation(size);
    },
    getStatusColor(status) {
      switch (status) {
        case 2:
          return "blue--text";
        case 1:
          return "green--text";
        default:
          return "";
      }
    },
    getStatusName(status) {
      switch (status) {
        case 2:
          return i18n.t("board.전송완료");
        case 1:
          return i18n.t("board.전송중");
        default:
          return i18n.t("board.전송대기");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.cr-file-list-wrapper ::v-deep {
  border: 1px solid rgba(0, 0, 0, 0.12);
  height: 150px;
  width: 100%;
  position: relative;
  .v-data-table {
    position: absolute;
    width: 100%;
    top: 0px;
    bottom: 0px;
    .v-data-table__wrapper {
      position: absolute;
      width: 100%;
      top: 0px;
      bottom: 0px;
      overflow-y: auto;
      overflow-x: hidden;
      > table {
        > tbody {
          .v-data-table__empty-wrapper {
            .no-data {
              height: 112px;
            }
          }
        }
        > thead > tr > th,
        > tbody > tr > td {
          padding: 0 0 0 12px;
          transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
        }
      }
    }
  }
}
</style>
