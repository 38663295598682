<template>
  <!-- 로딩중 -->
  <div v-if="loading" class="d-flex justify-center">
    <v-progress-circular :size="50" :width="4" color="grey" indeterminate />
  </div>
  <!-- 리스트 메인 -->
  <v-list v-else-if="list.length > 0" class="cr-search-list pa-0" outlined>
    <v-list-item
      v-for="(item, index) in list"
      :key="item.id"
      class="d-block px-0"
    >
      <v-list-item-content
        class="cr-list-element px-4 py-3"
        @click="onClickRow(item.id)"
      >
        <!-- 게시판 -->
        <v-list-item-subtitle class="d-flex align-center text-caption mb-1">
          <v-icon size="17" style="margin-right:2px">mdi-folder-outline</v-icon>
          <span v-html="getBoardName(item.boardId)" />
        </v-list-item-subtitle>
        <!-- 제목 -->
        <v-list-item-title
          class="d-flex align-center black--text font-weight-bold mb-1"
        >
          <span v-html="item.title" class="mr-1 cr-title-ellipsis" />
          <!-- 중요 -->
          <v-icon v-if="item.isImportant" color="primary" size="19">
            mdi-sticker
          </v-icon>
          <!-- 공지사항 -->
          <v-icon v-if="item.isNotice" color="primary" size="19">
            mdi-pin
          </v-icon>
        </v-list-item-title>
        <!-- 본문 -->
        <v-list-item-subtitle class="mb-2">
          <div v-html="item.content" class="cr-content-ellipsis" />
        </v-list-item-subtitle>
        <!-- 사용자 및 부가정보 -->
        <v-list-item-subtitle class="d-flex align-center">
          <!-- 사용자 -->
          <UserName
            :userId="item.userId"
            :userName="item.userName"
            :userEmail="item.userEmail"
          />
          <v-divider vertical class="my-1 mx-2 grey" />
          <!-- 날짜 -->
          <span v-html="convertDateFromMillis(item.updatedTimeMillis)" />
          <v-divider
            v-if="item.replyCount > 0 || item.fileCount > 0"
            vertical
            class="my-1 mx-2 grey"
          />
          <!-- 댓글 갯수 -->
          <div v-if="item.replyCount > 0" class="d-flex align-center mr-2">
            <v-icon size="19" class="mr-1" small>
              mdi-message-reply-text-outline
            </v-icon>
            <span>
              {{ item.replyCount }}
            </span>
          </div>
          <!-- 첨부파일 갯수 -->
          <div v-if="item.fileCount > 0" class="d-flex align-center">
            <v-icon size="19" class="mr-1" small>
              mdi-paperclip
            </v-icon>
            <span>
              {{ item.fileCount }}
            </span>
          </div>
        </v-list-item-subtitle>
        <!-- 태그 -->
        <v-list-item-subtitle class="d-flex align-center">
          <TagList v-if="item.tags.length > 0" :tags="item.tags" dense />
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-divider v-if="index < list.length - 1" :key="index" class="mx-4" />
    </v-list-item>
  </v-list>
  <!-- NO DATA -->
  <div
    v-else
    class="text-subtitle-2 grey--text text--darken-1"
    v-text="'검색 결과가 없습니다.'"
  />
</template>

<style lang="scss" scoped>
.cr-search-list::v-deep {
  .cr-list-element:hover {
    background: rgb(106 143 255 / 10%);
    cursor: pointer;
  }
  .cr-hlt {
    background: beige;
    color: black;
    font-style: normal;
  }
  .cr-title-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .cr-content-ellipsis {
    white-space: normal;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    line-height: 1.4;
  }
}
</style>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import moment from "moment";
import TagList from "@/board/views/components/common/TagList.vue";
import UserName from "@/board/views/components/common/item/UserName";

export default {
  components: { TagList, UserName },
  props: {
    listData: {
      type: Object,
      default: () => {},
      description: "게시글 목록과 데이타"
    }
  },
  mounted() {},
  data: () => ({}),
  watch: {},
  computed: {
    ...mapGetters("board", ["getBoardById"]),
    list() {
      return this.listData.list;
    },
    loading() {
      return this.listData.loading;
    }
  },
  methods: {
    ...mapActions("boardPost", ["loadPost"]),
    ...mapMutations("boardDialog", ["SET_DIALOG"]),
    getBoardName(boardId) {
      return this.getBoardById(boardId)?.name ?? "알 수 없음";
    },
    // 날짜 포맷
    convertDateFromMillis(millis, format = "YYYY.MM.DD") {
      return moment(millis).format(format);
    },
    // 게시글 클릭시
    async onClickRow(postId) {
      // 다이얼로그 호출
      this.SET_DIALOG({
        type: "viewPost",
        params: { postId: postId }
      });
    }
  }
};
</script>
