<template>
  <div
    class="cr-list-main d-flex justify-center pt-5"
    ref="listWrapper"
    @scroll="handleNotificationListScroll"
  >
    <div class="px-4" style="width:100%; max-width:1200px">
      <v-row justify="center">
        <!-- 리스트 메인 -->
        <v-col
          order="last"
          order-md="first"
          cols="12"
          md="7"
          class="pa-0 mr-0 mr-md-4 mr-lg-8"
        >
          <!-- 게시글 추가 -->
          <AddPostBox />
          <!-- 게시판 제목 & 보기 형태 -->
          <v-row class="cr-title-row ma-0">
            <v-col
              cols="12"
              class="cr-title-wrapper mx-auto d-flex align-center"
            >
              <div class="font-weight-bold">{{ boardTitle }}</div>
              <v-spacer></v-spacer>
              <v-btn-toggle mandatory v-model="viewType">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="cr-tutorial-board-timeline"
                      icon
                      small
                      :ripple="false"
                      v-bind="attrs"
                      v-on="on"
                      value="CARD"
                    >
                      <v-icon small>mdi-view-agenda</v-icon>
                    </v-btn>
                  </template>
                  <span v-text="$t('board.99')"></span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      small
                      :ripple="false"
                      v-bind="attrs"
                      v-on="on"
                      value="LIST"
                    >
                      <v-icon small>mdi-list-box</v-icon>
                    </v-btn>
                  </template>
                  <span v-text="$t('board.100')"></span>
                </v-tooltip>
              </v-btn-toggle>
            </v-col>
          </v-row>
          <!-- 리스트 -->
          <v-row class="cr-list-wrapper ma-0">
            <Card
              ref="cardView"
              v-if="getViewType == 'CARD'"
              :posts="getPostList"
            />
            <List
              ref="listView"
              v-if="getViewType == 'LIST'"
              :posts="getPostList"
            />
          </v-row>
          <!-- 맨 위로 버튼 -->
          <v-btn
            v-show="showMoveTopBtn"
            fab
            dark
            color="primary cr-move-top-btn"
            fixed
            bottom
            small
            class="mr-4"
            @click="goToTop(true)"
          >
            <v-icon dark>mdi-page-last</v-icon>
          </v-btn>
        </v-col>
        <!-- ASIDE -->
        <v-col order="first" order-md="last" cols="0" md="4" class="pa-0">
          <div class="pa-3">
            <Panel
              :type="'notice'"
              @onPanelTitleClick="onPanelTitleClick"
              @onPanelListClick="onPanelListClick"
            />
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cr-title-wrapper ::v-deep {
  .v-btn-toggle {
    .v-btn.v-btn--active {
      background-color: grey;
      .v-btn__content i {
        color: white;
      }
    }
    .v-btn {
      background-color: white;
    }
  }
}
</style>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import AddPostBox from "@/board/views/components/main/list/AddPostBox";
import Card from "@/board/views/components/main/list/Card";
import List from "@/board/views/components/main/list/List";
import Panel from "@/board/views/components/main/panel/Panel";
import { virtualBoardConverter } from "@/board/constant/boardType";

export default {
  components: { AddPostBox, Card, List, Panel },
  destroyed() {
    this.INIT_POST_LIST();
  },
  data: () => ({
    posts: [],
    showMoveTopBtn: false,
    // 페이지를 불러오는 중인지 여부 (중복 조회 방지)
    isLoading: false
  }),
  watch: {
    // route에 의해 폴더선택이 변경될때
    getRouteListInfo({ id: routeId }) {
      if (routeId == 0) return;
      this.goToTop();
    }
  },
  computed: {
    ...mapGetters("boardConfig", ["getViewType"]),
    ...mapGetters("boardPost", ["getPostById", "getPostList"]),
    ...mapGetters("boardRoute", ["getRouteListInfo"]),
    ...mapGetters("board", ["getActiveItems"]),
    viewType: {
      get() {
        return this.getViewType;
      },
      set(value) {
        this.INIT_POST_LIST();
        this.SET_VIEW_TYPE(value);
        const { id: boardId } = this.getRouteListInfo;
        const routeName = value === "CARD" ? "board_list" : "board_list_action";
        this.$router.push({
          name: routeName,
          params: {
            boardId: virtualBoardConverter(boardId),
            actionObj: JSON.stringify({
              viewType: value
            })
          }
        });
      }
    },
    boardTitle() {
      return this.getActiveItems[0]?.name;
    }
  },
  methods: {
    ...mapMutations("boardDialog", ["SET_DIALOG"]),
    ...mapMutations("boardConfig", ["SET_VIEW_TYPE"]),
    ...mapMutations("boardPost", ["INIT_POST_LIST"]),
    ...mapActions("boardPost", ["loadPostList", "loadPost"]),
    /** 무한 스크롤 정의*/
    handleNotificationListScroll(e) {
      if (this.getViewType !== "CARD") return;
      const { scrollHeight, scrollTop, clientHeight } = e.target;
      this.showMoveTopBtn = scrollTop > 0 ? true : false;
      const isAtTheBottom =
        scrollHeight - 50 < Math.ceil(scrollTop + clientHeight);
      // 일정 한도 밑으로 내려오면 함수 실행
      if (isAtTheBottom) this.handleLoadMore();
    },
    // 내려오면 api 호출하여 아래에 더 추가, total값 최대이면 호출 안함
    async handleLoadMore() {
      if (
        this.getPostList.page < this.getPostList.totalPage &&
        !this.isLoading
      ) {
        this.isLoading = true;
        await this.loadPostList({
          boardId: this.getRouteListInfo.id,
          page: this.getPostList.page + 1
        });
        this.isLoading = false;
      }
    },
    // 스크롤을 맨위로 올리고 싶을 때
    goToTop(isSmooth) {
      this.$refs["listWrapper"].scroll({
        top: 0,
        behavior: isSmooth ? "smooth" : "auto"
      });
    },
    // 패널 제목 클릭 이벤트
    onPanelTitleClick(type) {
      let routerName, params;
      switch (type) {
        case "notice":
          routerName = "board_list";
          params = { boardId: "notice" };
          break;
        default:
          break;
      }
      this.$router.push({
        name: routerName,
        params
      });
    },
    // 패널 리스트 클릭 이벤트
    async onPanelListClick(type, postId) {
      // 다이얼로그 호출
      this.SET_DIALOG({
        type: "viewPost",
        params: { postId }
      });
    }
  }
};
</script>
