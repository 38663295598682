<template>
  <v-row class="ma-0">
    <v-col class="py-0" cols="12">
      <v-checkbox v-model="check_1">
        <template v-slot:label>
          <div v-text="$t('board.59')"></div>
        </template>
      </v-checkbox>
    </v-col>
    <v-col class="py-0" cols="12">
      <v-checkbox v-model="check_2">
        <template v-slot:label>
          <div v-text="$t('board.60')" />
        </template>
      </v-checkbox>
    </v-col>
    <v-col class="py-0" cols="12">
      <v-checkbox v-model="check_3">
        <template v-slot:label>
          <div v-text="$t('board.61')" />
        </template>
      </v-checkbox>
    </v-col>
  </v-row>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from "vuex";
export default {
  props: {
    confirm: {
      default: false,
      type: Boolean,
      description: "확인 버튼"
    },
    confirmDisabled: {
      default: false,
      type: Boolean,
      description: "확인 버튼 Disabled 여부"
    }
  },
  data: () => ({
    check_1: false,
    check_2: false,
    check_3: false
  }),
  mounted() {
    this.$emit("update:confirmDisabled", true);
  },
  watch: {
    isAllCheck(value) {
      this.$emit("update:confirmDisabled", !value);
    },
    // 확인버튼 클릭
    async confirm(value) {
      if (value) {
        await this.deleteBoard(this.getParams.board.id);
        this.CLOSE_DIALOG();
      }
    }
  },
  computed: {
    ...mapGetters("boardDialog", ["getParams"]),
    isAllCheck() {
      const { check_1, check_2, check_3 } = this;
      return check_1 && check_2 && check_3;
    }
  },
  methods: {
    ...mapActions("board", ["deleteBoard"]),
    ...mapMutations("boardDialog", ["CLOSE_DIALOG"])
  }
};
</script>
