<template>
  <v-row>
    <!-- 타이틀 -->
    <v-col cols="12" class="d-flex align-end pb-1">
      <div class="d-flex align-center mr-2">
        <v-icon size="21" class="mr-1">{{ titleItems[type].icon }}</v-icon>
        <span class="text-body-1 black--text" v-text="titleItems[type].title" />
      </div>
      <span v-if="!listData.loading" class="text-caption">
        {{ `${listData.totalElements}건` }}
      </span>
    </v-col>
    <!-- 검색 조건 영역 -->
    <v-col cols="12">
      <PostSearchDetail v-if="type === 'post'" />
      <ReplySearchDetail v-else-if="type === 'reply'" />
      <FileSearchDetail v-else-if="type === 'file'" />
    </v-col>
    <!-- 리스트 영역 -->
    <v-col v-if="listData.loading || listData.totalElements > 0" cols="12">
      <PostSearchList v-if="type === 'post'" :listData="listData" />
      <ReplySearchList v-else-if="type === 'reply'" :listData="listData" />
      <FileSearchList v-else-if="type === 'file'" :listData="listData" />
    </v-col>
    <NoData v-else />
    <v-col cols="12">
      <Pagination
        v-if="!listData.loading && listData.totalElements > 0"
        type="list"
        :page="getPage + 1"
        :pageSize="getPageSize"
        :blockSize="5"
        :totalElements="listData.totalElements"
        @pageClick="pageClick"
      />
    </v-col>
  </v-row>
</template>

<style lang="scss" scoped></style>

<script>
import Pagination from "@/commons/views/Pagination";
import PostSearchDetail from "./detail/PostSearchDetail";
import ReplySearchDetail from "./detail/ReplySearchDetail";
import FileSearchDetail from "./detail/FileSearchDetail";
import PostSearchList from "./list/PostSearchList";
import ReplySearchList from "./list/ReplySearchList";
import FileSearchList from "./list/FileSearchList";
import NoData from "./NoData";
import { mapGetters } from "vuex";
export default {
  props: {
    type: {
      type: String,
      description: "post, reply, file"
    }
  },
  components: {
    Pagination,
    PostSearchDetail,
    ReplySearchDetail,
    FileSearchDetail,
    PostSearchList,
    ReplySearchList,
    FileSearchList,
    NoData
  },
  mounted() {},
  data: () => ({
    titleItems: {
      post: {
        icon: "mdi-text-box-outline",
        title: "게시글 검색 결과"
      },
      reply: {
        icon: "mdi-message-reply-text-outline",
        title: "댓글 검색 결과"
      },
      file: {
        icon: "mdi-paperclip",
        title: "첨부파일 검색 결과"
      }
    }
  }),
  watch: {},
  computed: {
    ...mapGetters("boardSearch", [
      "getPostSearchList",
      "getReplySearchList",
      "getFileSearchList",
      "getSearchParam"
    ]),
    // 현재 모듈의 리스트 데이타
    listData() {
      switch (this.type) {
        case "post":
          return this.getPostSearchList;
        case "reply":
          return this.getReplySearchList;
        case "file":
          return this.getFileSearchList;
        default:
          return [];
      }
    },
    getPage() {
      return this.getSearchParam?.query?.page ?? 0;
    },
    getPageSize() {
      return this.getSearchParam.pageSize ?? 10;
    }
  },
  methods: {
    pageClick(page) {
      const { query } = this.getSearchParam;

      this.$router.push({
        name: "board_search",
        params: {
          type: this.type,
          query: JSON.stringify({ ...query, page: page - 1 })
        }
      });
    }
  }
};
</script>
