<template>
  <div class="cr-config-main">
    <v-tabs v-model="selectedTab">
      <v-tab
        v-for="tab in tabs"
        v-show="tab.use"
        :key="tab.tabId"
        @click="() => routeConfigTab({ tabId: tab.tabId })"
      >
        {{ tab.name }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="selectedTab">
      <v-tab-item :transition="false">
        <BoardManageConfig ref="manage_config"></BoardManageConfig>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BoardManageConfig from "@/board/views/components/main/config/BoardManageConfig";
import i18n from "@/_locales";

export default {
  components: { BoardManageConfig },
  data() {
    return {
      selectedTab: 0,
      tabs: [{ tabId: "manage", name: i18n.t("board.98"), use: true }]
    };
  },
  // 생성시 초기 tab 설정
  mounted() {
    this.selectTab(this.getConfigTabType);
  },
  watch: {
    // 라우팅에따른 탭 효과 설정
    getConfigTabType(type) {
      this.selectTab(type);
    }
  },
  computed: {
    ...mapGetters("boardRoute", ["getConfigTabType"])
  },
  methods: {
    ...mapActions("boardRoute", ["routeConfigTab"]),
    selectTab(type) {
      const index = this.tabs.findIndex(t => t.tabId == type);
      if (index != -1) this.selectedTab = index;
    }
  }
};
</script>
<style lang="scss" scoped>
.cr-config-main {
  position: relative;
  height: 100%;
  .v-window ::v-deep {
    height: calc(100% - 48px);
    overflow-y: auto;
    .v-window__container {
      .v-window-item {
        .cr-config-wrapper {
          max-width: 1029px;
        }
      }
    }
  }
}
</style>
