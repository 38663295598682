var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticClass:"cr-file-list-wrapper",attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"dense":"","fixed-header":"","hide-default-footer":"","item-key":"key","mobile-breakpoint":0,"items-per-page":100,"headers":_vm.headers,"items":_vm.getItems},scopedSlots:_vm._u([{key:"item.deleteBtn",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.DELETE_FILE(item.key)}}},[_vm._v(" mdi-close ")])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-caption",class:_vm.getStatusColor(item.status)},[_vm._v(" "+_vm._s(_vm.getStatusName(item.status))+" ")])]}},{key:"item.size",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"grey--text"},[_vm._v(" "+_vm._s(_vm.byteCalculation(item.size))+" ")])]}},{key:"no-data",fn:function(){return [_c('div',{staticClass:"d-flex no-data align-center justify-center"},[_c('div',[_c('v-icon',{staticClass:"mb-2",attrs:{"color":"grey","size":"25"}},[_vm._v("mdi-plus-box-multiple")]),_c('div',{domProps:{"textContent":_vm._s(_vm.$t('board.37'))}})],1)])]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }