import { render, staticRenderFns } from "./BoardDetail.vue?vue&type=template&id=81bc7e4e&scoped=true&"
import script from "./BoardDetail.vue?vue&type=script&lang=js&"
export * from "./BoardDetail.vue?vue&type=script&lang=js&"
import style0 from "./BoardDetail.vue?vue&type=style&index=0&id=81bc7e4e&lang=scss&scoped=true&"
import style1 from "./BoardDetail.vue?vue&type=style&index=1&id=81bc7e4e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "81bc7e4e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VDivider,VIcon,VListItemAvatar,VRow})
