<template>
  <v-row justify="center" class="cr-trash-list-wrapper ma-0 pt-1">
    <v-col class="px-5">
      <!-- 휴지통 헤더 -->
      <div class="d-flex flex-column pb-4">
        <span class="text-h5 pb-1" v-text="$t('board.휴지통')"></span>
        <!-- 휴지통 보관기간 설명 -->
        <span
          class="text-body-2"
          v-html="
            $t('board.143', {
              value: getBoardServiceConfig.deletedPostMaxDuration
            })
          "
        >
        </span>
      </div>
      <!-- 버튼 그룹 -->
      <div class="btn-group pb-2">
        <v-btn
          elevation="0"
          color="primary"
          class="mr-2"
          :disabled="selected < 1"
          @click="unDeletePost"
        >
          {{ $t("board.복구") }}
        </v-btn>
        <v-btn
          elevation="0"
          color="error"
          :disabled="selected < 1"
          @click="deletePost"
        >
          {{ $t("board.영구삭제") }}
        </v-btn>
      </div>
      <!-- 리스트 테이블 -->
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="postData"
        :server-items-length="getPageable.totalElements"
        :loading="showLoading"
        :options.sync="options"
        must-sort
        item-key="id"
        show-select
        hide-default-footer
        checkbox-color="primary"
        @click:row="onClickRow"
        :class="selected.length > 0 ? 'elevation-1' : ''"
      >
        <!-- 게시글 정보 -->
        <template v-slot:[`item.post`]="{ item }">
          <div class="d-flex flex-column py-2">
            <!-- 제목 -->
            <div class="text-subtitle-1 black--text">
              <span>{{ item.title }}</span>
            </div>
            <!-- 부가정보 -->
            <div
              class="d-flex align-center text-caption grey--text font-weight-medium"
            >
              <UserName
                :userId="item.userId"
                :userName="item.userName"
                :userEmail="item.userEmail"
              />
              <v-divider
                v-if="item.replyCount > 0 || item.fileCount > 0"
                vertical
                class="my-1 mx-2 grey"
              />
              <!-- 댓글 갯수 -->
              <div v-if="item.replyCount > 0" class="d-flex align-center mr-2">
                <v-icon size="19" class="mr-1" small>
                  mdi-message-reply-text-outline
                </v-icon>
                <span>
                  {{ item.replyCount }}
                </span>
              </div>
              <!-- 첨부파일 갯수 -->
              <div v-if="item.fileCount > 0" class="d-flex align-center">
                <v-icon size="19" class="mr-1" small>
                  mdi-paperclip
                </v-icon>
                <span>
                  {{ item.fileCount }}
                </span>
              </div>
            </div>
          </div>
        </template>
      </v-data-table>
      <!-- 페이지네이션 -->
      <div class="d-flex justify-center pt-2">
        <Pagination
          v-if="getPageable.totalElements > 0"
          type="list"
          :page="page"
          :pageSize="getPostPageSize"
          :blockSize="5"
          :totalElements="getPageable.totalElements"
          @pageClick="pageClick"
        />
      </div>
    </v-col>
  </v-row>
</template>

<style lang="scss" scoped>
.cr-trash-list-wrapper ::v-deep {
  height: 100%;
  overflow-y: auto;
  .cr-data-table-header {
    display: flex;
    align-items: center;
    white-space: nowrap;
  }
  thead.v-data-table-header {
    tr {
      white-space: nowrap;
    }
  }
  tbody {
    tr:hover {
      cursor: pointer;
      background: rgb(210 210 210 / 15%) !important;
    }
    tr.v-data-table__selected {
      background: rgb(106 143 255 / 10%) !important;
    }
  }
  .btn-group {
    .v-btn--disabled {
      background: white !important;
    }
  }
}
</style>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import Pagination from "@/commons/views/Pagination";
import UserName from "@/board/views/components/common/item/UserName";
import i18n from "@/_locales";
import moment from "moment";

export default {
  components: { Pagination, UserName },
  mounted() {
    this.showLoading = this.getLoading;
    this.loadTrashList(1);
  },
  data: () => ({
    headers: [
      {
        text: i18n.t("board.게시글"),
        width: "50%",
        align: "start",
        sortable: false,
        value: "post"
      },
      {
        text: i18n.t("board.수정일"),
        align: "center",
        value: "updatedTimeMillis"
      },
      {
        text: i18n.t("board.삭제일"),
        align: "center",
        value: "deletedTimeMillis"
      },
      { text: i18n.t("board.삭제자"), align: "start", value: "deleteUserName" }
    ],
    showLoading: false
  }),
  watch: {
    // route에 의해 폴더선택이 변경될때
    getRouteListInfo() {
      this.loadTrashList(1);
    },
    // 정렬 변경될때
    options(n, p) {
      if (n.sortDesc[0] === p.sortDesc[0] && n.sortBy[0] === p.sortBy[0])
        return;
      this.loadTrashList(this.page);
    },
    // 로딩해제 지연 적용
    getLoading(n) {
      if (n) {
        this.showLoading = n;
        return;
      }
      setTimeout(() => {
        this.showLoading = n;
      }, 150);
    }
  },
  methods: {
    ...mapActions("snackbar", ["openSnackbar"]),
    ...mapActions("confirm", ["confirm"]),
    ...mapActions("boardPost", ["loadPost"]),
    ...mapActions("boardTrash", ["loadTrashList", "deleteSelectedPost"]),
    ...mapMutations("boardDialog", ["SET_DIALOG"]),
    ...mapMutations("boardTrash", [
      "INIT",
      "SET_SELECTED",
      "SET_SORT",
      "SET_DESC"
    ]),
    // 날짜 포맷
    convertDateFromMillis(millis, format = "YYYY.MM.DD (dd) HH:mm") {
      return moment(millis).format(format);
    },
    // 게시글 영구삭제
    deletePost() {
      this.confirm({
        headline: "",
        message: i18n.t("board.146"),
        callback: () => this.deleteSelectedPost()
      });
    },
    // 게시글 복구
    unDeletePost() {
      this.SET_DIALOG({
        title: "게시글 복원",
        type: "movePost",
        confirmDisabled: true
      });
    },
    pageClick(page) {
      this.loadTrashList(page);
    },
    // 게시글 클릭시
    async onClickRow(item) {
      // 다이얼로그 호출
      this.SET_DIALOG({
        type: "viewPost",
        params: {
          postId: item.id
        }
      });
    }
  },
  computed: {
    ...mapGetters("board", ["getBoardById"]),
    ...mapGetters("boardConfig", ["getPostPageSize"]),
    ...mapGetters("serviceConfig", ["getBoardServiceConfig"]),
    ...mapGetters("boardRoute", ["getRouteListInfo"]),
    ...mapGetters("boardTrash", [
      "getTrashList",
      "getPageable",
      "getSelected",
      "getLoading"
    ]),
    // 표시되는 게시글 데이타
    postData() {
      const data = this.getTrashList.map(p => ({
        ...p,
        updatedTimeMillis: this.convertDateFromMillis(p.updatedTimeMillis),
        deletedTimeMillis: this.convertDateFromMillis(p.deletedTimeMillis)
      }));
      return data;
    },
    // 선택된 게시글
    selected: {
      get: function() {
        return this.getSelected;
      },
      set: function(val) {
        this.SET_SELECTED(val);
      }
    },
    // 정렬 옵션
    options: {
      get: function() {
        const options = {
          mustSort: true,
          sortBy: [this.getPageable.sort],
          sortDesc: [this.getPageable.desc]
        };
        return options;
      },
      set: function(val) {
        this.SET_SORT(val.sortBy[0]);
        this.SET_DESC(val.sortDesc[0]);
      }
    },
    page() {
      return this.getPageable.page + 1;
    }
  },
  destroyed() {
    this.INIT();
  }
};
</script>
