<template>
  <v-row class="cr-banner-wrapper red accent-3 white--text mb-3">
    <v-col
      cols="12"
      class="d-flex justify-center align-center"
      style="height:52px"
    >
      <!-- 삭제된 게시판 입니다. -->
      <span class="mr-3" v-text="$t('board.95')"></span>
      <!-- 삭제 취소 -->
      <v-btn
        v-if="showCancelBtn"
        small
        text
        height="30"
        outlined
        color="white"
        tile
        @click="openCancelConfirm"
        v-text="$t('board.96')"
      >
      </v-btn>
    </v-col>
    <!-- 몇일 뒤 삭제 안내 -->
    <v-col cols="12" class="d-flex justify-center align-center py-0">
      <v-icon color="white" class="mr-2">mdi-information-outline</v-icon>
      <span v-text="deletedTime" class="text-body-2"></span>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { convertLastChangeDate } from "@/commons/utils/moment";
import i18n from "@/_locales";

export default {
  computed: {
    ...mapGetters("board", ["getBoardById", "getActiveItems"]),
    showCancelBtn() {
      if (this.getActiveItems.length == 0) return false;
      const [activeItem = {}] = this.getActiveItems;

      if (activeItem.id == activeItem.groupId) return true;
      const parent = this.getBoardById(activeItem.groupId);
      if (parent.userPrivilege != "ADMIN") return false;

      return !parent.isDeleted;
    },
    deletedTime() {
      const [activeItem = {}] = this.getActiveItems;
      const board = this.getBoardById(activeItem.id);
      let deleteTime = new Date(board.deletedTimeMillis);
      deleteTime.setDate(deleteTime.getDate() + 7);
      const result = convertLastChangeDate(deleteTime) * -1;

      return result > 0
        ? i18n.t("board.141", { value: result }) // ?일뒤 자동 삭제됨
        : i18n.t("board.142"); // 곧 자동 삭제됨
    }
  },

  methods: {
    ...mapActions("confirm", ["confirm", "disagree"]),
    ...mapActions("board", ["deleteCancel"]),
    // confirm 다이얼로그창 생성 함수
    openConfirmDialog(headline, message, callback = () => {}) {
      this.confirm({ headline, message, callback });
    },
    openCancelConfirm() {
      this.openConfirmDialog("", i18n.t("board.97"), async () => {
        this.disagree();
        await this.deleteCancel(this.getActiveItems[0].id);
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.cr-banner-wrapper ::v-deep {
  padding: 11px 24px;
  .v-btn.v-btn--outlined.v-btn--text.v-btn--tile {
    border-color: white !important;
  }
}
</style>
