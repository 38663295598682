<template>
  <div class="cr-privilege">
    <div
      class="cr-title font-weight-bold grey--text text--darken-1 text-subtitle-2"
    >
      {{ title }}
    </div>
    <div class="my-2 cr-member-input">
      <!-- -->
      <!-- 자동완성 -->
      <Autocomplete
        :placeholder="placeholder"
        key="share_target"
        :selection.sync="selection"
        :directInput="false"
        :useChips="false"
        :listHeight="150"
        :typeList="['MEMBER']"
      />
    </div>
    <div class="mb-2 cr-member-list">
      <v-list single-line dense>
        <v-list-item v-for="member in members" :key="member.id">
          <v-icon class="mr-2" v-if="member.isNew" color="red">
            mdi-alpha-n-circle
          </v-icon>
          <v-icon class="mr-2" v-else color="indigo">
            mdi-check
          </v-icon>

          <v-list-item-content>
            <v-list-item-title
              class="subtitle-1"
              v-text="member.memberName"
            ></v-list-item-title>
          </v-list-item-content>

          <v-list-item-action>
            <div style="display: inline-flex;">
              <v-select
                v-show="type == 'member'"
                dense
                solo
                flat
                hide-details
                class="button"
                :items="privilegeList"
                style="min-width: 141px;"
                v-model="member.privilegeType"
                @change="selectPrivilege(member)"
              ></v-select>
              <!--  -->

              <v-btn
                v-show="showDeleteBtn(member)"
                icon
                @click="deleteColumn(member)"
              >
                <v-icon small color="grey lighten-1">mdi-close</v-icon>
              </v-btn>
            </div>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cr-privilege ::v-deep {
  .cr-member-input {
    .cr-autocomplete-wrapper {
      background-color: white;

      .v-input {
        .v-text-field__slot {
          padding-left: 4px;
        }
      }
    }
  }
  .cr-member-list {
    background-color: white;
    height: 200px;
    overflow: auto;
    border: 1px solid #dfdfdf;
    .v-list .v-list-item {
      max-height: 40px;
      > .v-list-item__action {
        .v-input {
          .v-select__selections {
            > input {
              display: none;
            }
          }
        }
      }
    }
  }
}
</style>

<script>
import Autocomplete from "@/commons/views/autocomplete/Autocomplete";
import i18n from "@/_locales";

export default {
  props: ["title", "type", "placeholder", "members", "deleteList"],
  components: { Autocomplete },
  data: () => ({
    // 입력값
    selection: {},
    // 권한 타입
    privilegeList: [
      { text: i18n.t("board.읽기"), value: "READ" },
      { text: i18n.t("board.편집"), value: "READ_WRITE" }
    ]
  }),
  watch: {
    // 구성원 지정 시 리스트에 추가하는 함수
    selection(selection) {
      this.plusMember(selection);
    }
  },
  methods: {
    showDeleteBtn(member) {
      if (this.type == "member") return true;

      return member.isSuperAdmin ? false : true;
    },
    // 멤버 추가
    plusMember(item) {
      this.$emit("updateMember", this.type, item);
    },
    // 권한 수정
    selectPrivilege(member) {
      const index = this.members.findIndex(m => m.memberId == member.memberId);

      const target = this.members[index];
      // 기존 멤버의 권한 변경일 경우 수정 플래그를 업데이트 해준다.
      if (!target.isNew) target.isUpdated = true;
    },
    // 멤버 삭제
    deleteColumn(member) {
      const index = this.members.findIndex(m => m.memberId == member.memberId);

      const target = this.members[index];
      if (!target.isNew) {
        // target.isUpdated = false;
        this.deleteList.push(target);
      }
      this.members.splice(index, 1);
    }
  }
};
</script>
