<template>
  <v-row
    class="post-dialog-wrapper ma-0"
    @dragenter="e => dragenter(e)"
    @dragleave="e => dragleave(e)"
    @drop="e => drop(e)"
    @dragover.prevent
  >
    <v-col cols="12" class="pa-0">
      <!-- 게시판 선택 -->
      <v-row no-gutters class="px-3 mb-3">
        <v-col
          class="cr-title d-flex align-center"
          cols="12"
          sm="3"
          v-text="$t('board.게시판')"
        >
        </v-col>
        <v-col class="d-flex align-center" cols="12" sm="9">
          <InputSelector
            ref="refInputSelector"
            :width="'300px'"
            :label="$t('board.64')"
            :initItems="boards"
            :selectedItem.sync="selectBoard"
          >
          </InputSelector>
        </v-col>
      </v-row>
      <!-- 게시글 제목 -->
      <v-row no-gutters class="px-3 mb-3">
        <v-col
          class="cr-title d-flex align-center"
          cols="12"
          sm="3"
          v-text="$t('board.65')"
        >
        </v-col>
        <v-col class="d-flex align-center" cols="12" sm="9">
          <v-text-field
            dense
            outlined
            hide-details
            autocomplete="off"
            ref="newTitle"
            :placeholder="$t('board.66')"
            v-model="title"
          ></v-text-field>
        </v-col>
      </v-row>
      <!-- 첨부 파일 -->
      <v-row no-gutters class="px-3 mb-3">
        <v-col class="cr-title d-flex align-center" cols="12" sm="3">
          <span class="mr-2" v-text="$t('board.첨부파일')"></span>
          <v-btn
            v-show="getTotalCount > 0"
            icon
            x-small
            @click="showFileListBtn = !showFileListBtn"
          >
            <v-icon class="cr-attach-expand-icon">
              {{ showFileListBtn ? "mdi-chevron-up" : "mdi-chevron-down" }}
            </v-icon>
          </v-btn>
        </v-col>
        <v-col class="d-flex align-center" cols="12" sm="9">
          <Attach :showAttachList.sync="showFileListBtn" />
        </v-col>
      </v-row>
      <!-- 태그 -->
      <v-row no-gutters class="px-3 mb-3">
        <v-col
          class="cr-title d-flex align-center"
          cols="12"
          sm="3"
          v-text="'태그'"
        >
        </v-col>
        <v-col cols="12" sm="9" style="padding-top:5px;">
          <TagInput
            :viewTags="tags"
            :myTags="getTags"
            @add:tag="addTag"
            @delete:tag="deleteTag"
          />
        </v-col>
      </v-row>
      <!-- 추가옵션 -->
      <v-row no-gutters class="px-3 mb-3">
        <v-col
          class="cr-title  d-flex align-center"
          :class="$vuetify.breakpoint.smAndDown ? 'mobile' : ''"
          cols="12"
          sm="3"
        >
          <span class="mr-2" v-text="$t('board.추가옵션')" />
          <v-btn
            v-show="hasOptions"
            @click="showOptions = !showOptions"
            icon
            x-small
          >
            <v-icon>
              {{ showOptions ? "mdi-chevron-up" : "mdi-chevron-down" }}
            </v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" sm="9">
          <v-row class="cr-options-area">
            <!-- 추가옵션 버튼 -->
            <v-col cols="12">
              <!-- 공지사항 -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-on="on"
                    v-bind="attrs"
                    :color="isNotice ? 'primary' : 'grey'"
                    @click.stop="toggleIsNotice()"
                  >
                    <v-icon>mdi-pin</v-icon>
                  </v-btn>
                </template>
                {{ $t("board.공지사항") }}
              </v-tooltip>
              <!-- 중요표시 -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-on="on"
                    v-bind="attrs"
                    :color="isImportant ? 'primary' : 'grey'"
                    @click.stop="toggleIsImportant()"
                  >
                    <v-icon>mdi-sticker</v-icon>
                  </v-btn>
                </template>
                {{ $t("board.67") }}
              </v-tooltip>
              <!-- 링크공유 -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-on="on"
                    v-bind="attrs"
                    :color="link ? 'primary' : 'grey'"
                    @click.stop="showAddLinkDialog = true"
                  >
                    <v-icon>mdi-link</v-icon>
                  </v-btn>
                </template>
                {{ $t("board.138") }}
              </v-tooltip>
            </v-col>
            <!-- 추가 옵션 확장 영역 -->
            <v-col v-if="hasOptions && showOptions" cols="12" class="pt-0 pl-5">
              <!-- 링크 표시 -->
              <div class="d-flex align-center">
                <a
                  v-text="link"
                  class="d-block mr-2 cr-link"
                  @click.stop="openLink"
                />
                <v-btn icon x-small @click.stop="deleteLink">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <!-- 내용 -->
    <v-col cols="12" class="py-0 pt-2">
      <TinymceEditor
        v-if="isShow"
        ref="editorContent"
        :useImage="true"
        :useImagePaste="true"
        :callback="ImageUploadCallback"
      />
    </v-col>
    <v-overlay
      :style="overlay ? 'border: 4px solid #1867c0;' : ''"
      absolute
      opacity="0.85"
      v-model="overlay"
    >
      <div class="d-flex justify-center">
        <v-icon class="mb-5 mx-auto" style="font-size: 110px;">
          mdi-cloud-upload
        </v-icon>
      </div>
      <div v-text="$t('board.68')"></div>
    </v-overlay>
    <AddLinkDialog
      v-if="showAddLinkDialog"
      :link.sync="link"
      :showAddLinkDialog.sync="showAddLinkDialog"
    />
  </v-row>
</template>
<script>
import { uploadFile } from "@/board/api/board.api";
import { mapGetters, mapMutations, mapActions } from "vuex";
import InputSelector from "@/board/views/components/common/InputSelector";
import AddLinkDialog from "@/board/views/components/dialog/sub/AddLinkDialog";
import TagInput from "@/commons/views/tag/autocomplete";
import Attach from "@/board/views/components/common/attach/Attach";
import TinymceEditor from "@/commons/views/editor/tinymce/TinymceEditor";

import { isBlank } from "@/commons/utils/validation";
import uploadType from "@/board/constant/uploadType";
import i18n from "@/_locales";

export default {
  components: { InputSelector, TagInput, Attach, AddLinkDialog, TinymceEditor },
  props: {
    confirm: {
      default: false,
      type: Boolean,
      description: "확인 버튼"
    },
    confirmDisabled: {
      default: false,
      type: Boolean,
      description: "확인 버튼 Disabled 여부"
    }
  },
  data: () => ({
    boards: [],
    selectBoard: {},
    title: "",
    isImportant: false,
    isNotice: false,
    link: "",
    content: "",
    tags: [],
    showFileListBtn: false,
    overlay: false,
    counter: 0,
    showAddLinkDialog: false,
    showOptions: false
  }),
  async mounted() {
    if (this.getBoards.length == 0) await this.loadBoards();
    const boards = this.getBoards.map(board => ({ ...board }));
    boards.forEach(b => {
      if (b.children) {
        const children = b.children;
        delete b.children;
        this.boards = [...this.boards, b, ...children];
      } else {
        this.boards = [...this.boards, b];
      }
    });

    // 쓰기 권한이 없는 게시판 필터링
    this.boards = this.boards.filter(
      b =>
        b.id > 0 &&
        (b.userPrivilege == "ADMIN" || b.userPrivilege == "READ_WRITE") &&
        !b.isDeleted
    );

    // 게시글 수정일 경우 기존값을 넣어준다.
    if (this.dialogType == "editPost" || this.dialogType == "sharePost") {
      const { post } = this.getParams;
      const [item = {}] = this.boards.filter(b => b.id == post.boardId);
      this.selectBoard = item;
      this.title = post.title;
      this.isImportant = post.isImportant;
      this.isNotice = post.isNotice;
      this.link = post.sharedLink?.link ?? "";
      this.content = post.content;
      this.tags = post.tags;

      // 기존 첨부파일 전송완료된 상태로 추가
      if (post.files.length > 0)
        this.setFiles(post.files.map(file => ({ ...file, status: 2 })));
      if (this.getTotalCount > 0) this.showFileListBtn = true;
    } else {
      // 아닌 경우 현재 라우팅 된 게시판을 넣어준다.
      if (this.getRouteListInfo.id > 0 && this.getTreeActive) {
        const [item = {}] = this.boards.filter(
          b => b.id == this.getRouteListInfo.id
        );
        this.selectBoard = item;
      }
    }
    setTimeout(() => {
      this.SET_INIT({
        editorId: "tiny_post",
        content: this.content,
        height: 500
      });
    }, 0);
    // 에디터 클릭시 팝업 메뉴 닫기
    setTimeout(() => {
      const editor = window.tinymce.activeEditor.iframeElement;
      editor.contentWindow.document.addEventListener(
        "click",
        this.closePositioningMenu
      );
    }, 0);
  },
  destroyed() {
    this.UPLOAD_RESET();
  },
  watch: {
    confirm(value) {
      if (value) {
        this.save();
        this.$emit("update:confirm", false);
      }
    },
    hasOptions(n) {
      if (n) this.showOptions = true;
    }
  },
  computed: {
    ...mapGetters("editor", ["isShow", "getContent"]),
    ...mapGetters("board", ["getBoards"]),
    ...mapGetters("boardConfig", ["getTags"]),
    ...mapGetters("boardDialog", ["dialogType", "getParams"]),
    ...mapGetters("boardRoute", ["getRouteListInfo", "getTreeActive"]),
    ...mapGetters("boardFile", [
      "getTotalCount",
      "getFileIds",
      "getItems",
      "isUploadComplete"
    ]),
    // 선택할 게시판이 없을 경우 게시판 선택 disabled
    selectBtnDisabled() {
      return this.boards.length > 0 ? false : true;
    },
    // 게시판 선택 여부
    isSelected() {
      return this.selectBoard.id;
    },
    // 추가옵션 Collapse 버튼 표시여부
    hasOptions() {
      return this.link ? true : false;
    }
  },
  methods: {
    ...mapActions("board", ["loadBoards"]),
    ...mapMutations("editor", ["SET_INIT"]),
    ...mapMutations("boardDialog", ["CLOSE_DIALOG"]),
    ...mapMutations("boardFile", ["UPLOAD_RESET"]),
    ...mapActions("snackbar", ["openSnackbar"]),
    ...mapActions("boardPost", ["addPost", "updatePostView", "updatePost"]),
    ...mapActions("boardFile", ["setFiles", "uploadFile"]),
    ...mapActions("confirm", { openConfirm: "confirm" }),
    ...mapActions("positioningMenu", ["closePositioningMenu"]),
    // 저장
    async save() {
      // 유효성 검증
      if (!this.isSelected)
        return this.openSnackbar({
          message: i18n.t("board.69")
        });
      if (isBlank(this.title)) {
        this.$refs.newTitle.focus();
        return this.openSnackbar({
          message: i18n.t("board.70")
        });
      }
      if (isBlank(this.getContent)) {
        return this.openSnackbar({
          message: i18n.t("board.71")
        });
      }
      if (this.title.length > 100) {
        this.$refs.newTitle.focus();
        return this.openSnackbar({
          message: i18n.t("board.170", { length: "100" })
        });
      }

      // 확인 버튼 비활성화
      this.$emit("update:confirmDisabled", true);

      // 게시글 작성 파라미터
      let param = {
        boardId: this.selectBoard.id,
        title: this.title,
        content: this.getContent,
        isImportant: this.isImportant,
        isNotice: this.isNotice,
        link: this.link,
        tags: this.tags,
        type: this.dialogType == "sharePost" ? "MAIL" : "BOARD"
      };

      // 첨부파일 업로드 && 게시글 작성
      if (this.getItems.length > 0 && !this.isUploadComplete) {
        this.uploadFile({
          uploadType: uploadType.POST,
          callbackCompleted: async () => {
            if (
              this.dialogType == "addPost" ||
              this.dialogType == "sharePost"
            ) {
              // 게시글 생성
              const post = await this.addPost(param);
              this.$emit("onSaved", post);
            } else {
              // 게시글 수정
              param.id = this.getParams.post.id;
              this.$route.name == "board_post_view"
                ? await this.updatePostView(param)
                : await this.updatePost(param);
            }
            // 확인 버튼 활성화
            this.$emit("update:confirmDisabled", false);
          },
          callbackCanceled: () => {
            this.$emit("update:confirmDisabled", false);
          },
          callbackFailed: () => {
            this.$emit("update:confirmDisabled", false);
          }
        });
        return;
      }

      // 게시글 작성
      if (this.dialogType == "addPost" || this.dialogType == "sharePost") {
        // 게시글 생성
        const post = await this.addPost(param);
        this.$emit("onSaved", post);
      } else {
        // 게시글 수정
        param.id = this.getParams.post.id;
        this.$route.name == "board_post_view"
          ? await this.updatePostView(param)
          : await this.updatePost(param);
      }

      // 확인 버튼 활성화
      this.$emit("update:confirmDisabled", false);
    },
    drop(e) {
      e.preventDefault();
      this.counter = 0;
      this.overlay = false;
      const droppedFiles = e.dataTransfer.files;
      if (!droppedFiles) return;

      this.addFiles(droppedFiles);
    },
    dragenter(e) {
      e.preventDefault();
      this.counter += 1;
      this.overlay = true;
    },
    dragleave(e) {
      e.preventDefault();
      this.counter -= 1;
      if (this.counter === 0) {
        this.overlay = false;
      }
    },
    // vuex에 file저장
    addFiles(files) {
      this.setFiles(files);
      this.showFileListBtn = true;
    },
    toggleIsImportant() {
      this.isImportant = !this.isImportant;
    },
    toggleIsNotice() {
      this.isNotice = !this.isNotice;
    },
    // 링크 삭제
    deleteLink() {
      this.openConfirmDialog("", i18n.t("board.137"), () => {
        this.link = "";
      });
    },
    // confirm 다이얼로그창 생성 함수
    openConfirmDialog(headline, message, callback = () => {}) {
      this.openConfirm({ headline, message, callback });
    },
    // 링크 새창으로 열기
    openLink() {
      window.open(this.link, "_blank");
    },
    ImageUploadCallback(callback) {
      const input = document.createElement("input");
      input.setAttribute("type", "file");
      input.setAttribute("accept", "image/*");

      input.onchange = async function() {
        const file = this.files[0];
        const { data, status } = await uploadFile(file, uploadType.INLINE_IMG);
        if (status != 200) {
          // 이미지업로드실패
          return alert(
            status == 507 ? i18n.t("board.128") : i18n.t("board.123")
          );
        }
        // 성공시 이미지 URL 치환
        const host_url = window.location.origin;
        callback(
          `${host_url}/api/board/file/img/${
            data.id
          }?access_token=${localStorage.getItem("access_token")}`,
          { alt: "image file" }
        );
      };
      input.click();
    },
    // 태그 추가
    addTag(tag) {
      if (this.tags.filter(t => t == tag).length > 0) return;
      this.tags = [...this.tags, tag];
    },
    // 태그 삭제
    deleteTag(tag) {
      this.tags = this.tags.filter(t => t != tag);
    }
  }
};
</script>

<style lang="scss" scoped>
.post-dialog-wrapper ::v-deep {
  .cr-title {
    height: 40px;
  }
  .cr-options-area {
    overflow: hidden;
    .cr-link {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
