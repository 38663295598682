<template>
  <v-row dense class="pb-8">
    <!-- 패널 제목 Part -->
    <v-col cols="12">
      <div class="d-flex align-center justify-space-between">
        <div class="d-flex align-center">
          <h2 class="mr-2">{{ panelOption.title }}</h2>
          <v-progress-circular
            v-if="listData.loading"
            :size="20"
            :width="3"
            color="grey"
            indeterminate
          />
          <span v-else>{{ `${listData.totalElements}건` }}</span>
        </div>
        <v-btn
          v-if="!listData.last && !listData.loading"
          plain
          @click="routeModule"
        >
          더보기
        </v-btn>
      </div>
    </v-col>
    <!-- 패널 리스트 Part -->
    <v-col cols="12" v-if="!listData.loading">
      <PostSearchList v-if="type === 'post'" :listData="listData" />
      <ReplySearchList v-else-if="type === 'reply'" :listData="listData" />
      <FileSearchList v-else-if="type === 'file'" :listData="listData" />
    </v-col>
  </v-row>
</template>

<style lang="scss" scoped></style>

<script>
import PostSearchList from "../list/PostSearchList";
import ReplySearchList from "../list/ReplySearchList";
import FileSearchList from "../list/FileSearchList";
import { mapGetters } from "vuex";
export default {
  components: { PostSearchList, ReplySearchList, FileSearchList },
  props: {
    type: {
      type: String,
      description: "패널 타입",
      default: "post"
    },
    listData: {
      type: Object,
      description: "목록 데이타",
      default: () => ({})
    }
  },
  mounted() {},
  data: () => ({
    panelType: {
      post: { title: "게시글" },
      reply: { title: "댓글" },
      file: { title: "첨부파일" }
    }
  }),
  computed: {
    ...mapGetters("boardSearch", ["getSearchParam"]),
    panelOption() {
      return this.panelType[this.type];
    }
  },
  methods: {
    // 각 모듈별 검색 목록 이동
    routeModule() {
      const {
        query: { keyword = "" }
      } = this.getSearchParam;

      const queryObj = {
        keyword: keyword,
        searchType: "ALL"
      };

      this.$router.push({
        name: "board_search",
        params: {
          type: this.type,
          query: JSON.stringify(queryObj)
        }
      });
    }
  }
};
</script>
