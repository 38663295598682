<template>
  <v-dialog persistent max-width="700px" :value="show" @input="show = false">
    <v-card>
      <v-card-title>
        <span class="text-h6 font-weight-bold" v-text="$t('board.38')"></span>
      </v-card-title>
      <v-card-text>
        <div style="padding: 8px 0px;">
          <span style="color: #1976d2;">
            {{ $t("board.134", { value: getCompletedCount }) }}
          </span>
          <span style="color: #333;">
            {{ $t("board.134", { value: getTotalCount }) }}
          </span>
          <span style="fontSize: 13px;">
            {{
              `${byteCalculation(getTotalLoadedSize)} / ${byteCalculation(
                getTotalSize
              )}`
            }}
          </span>
          <span style="float: right;">
            {{ getRemainingTime }}
          </span>
        </div>
        <v-progress-linear rounded :value="getTotalProgress" height="30">
          <strong>{{ Math.ceil(getTotalProgress) }}%</strong>
        </v-progress-linear>
        <v-data-table
          dense
          fixed-header
          hide-default-footer
          item-key="key"
          class="cr-uploader-data-table"
          style="padding-top: 20px;"
          :headers="headers"
          :items="getItems"
          :items-per-page="10"
          :server-items-length="10"
        >
          <!-- 전송량 -->
          <template v-slot:[`item.size`]="{ item }">
            {{
              `${byteCalculation(item.loaded)} / ${byteCalculation(item.size)}`
            }}
          </template>
          <!-- 전송 상태 -->
          <template v-slot:[`item.progress`]="{ item }">
            <v-progress-linear
              v-if="item.status == 1"
              rounded
              :value="item.progress"
              height="12"
            />
            <div v-else>
              {{ item.status == 0 ? $t("board.대기중") : $t("board.전송완료") }}
            </div>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          text
          outlined
          :disabled="getTotalProgress == 100"
          @click="show = false"
        >
          {{ $t("common.취소") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.v-dialog__content >>> .v-dialog {
  overflow: hidden;
}
.v-dialog__content >>> .v-dialog > .v-card > .v-card__text {
  overflow: auto;
}
.v-data-table.cr-uploader-data-table >>> .v-data-table__wrapper {
  height: 190px;
  border: thin solid rgba(0, 0, 0, 0.12);
}
.v-data-table.cr-uploader-data-table >>> table {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
.v-data-table.cr-uploader-data-table
  >>> .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td {
  border: none;
}
.v-data-table.cr-uploader-data-table
  >>> .v-data-table__wrapper
  > table
  > tbody
  > tr.v-data-table__mobile-table-row
  > td.v-data-table__mobile-row
  > .v-data-table__mobile-row__cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 150px;
}
</style>

<script>
import { mapGetters, mapActions } from "vuex";
import { byteCalculation } from "@/commons/utils/byteCalculation";
import i18n from "@/_locales";

export default {
  data: () => ({
    headers: [
      { text: i18n.t("board.파일명"), sortable: false, value: "name" },
      {
        text: i18n.t("board.전송량"),
        sortable: false,
        width: 220,
        value: "size"
      },
      {
        text: i18n.t("board.전송상태"),
        sortable: false,
        width: 90,
        value: "progress"
      }
    ]
  }),
  computed: {
    ...mapGetters("boardFile", [
      "showUploader",
      "getItems",
      "getTotalProgress",
      "getTotalCount",
      "getCompletedCount",
      "getTotalSize",
      "getTotalLoadedSize",
      "getRemainingTime",
      "isUploadComplete"
    ]),
    show: {
      get() {
        return this.showUploader;
      },
      set() {
        return this.closeUploader();
      }
    }
  },
  methods: {
    ...mapActions("boardFile", ["closeUploader"]),
    byteCalculation(size) {
      return byteCalculation(size);
    }
  }
};
</script>
