<template>
  <div
    class="cr-list-main d-flex justify-center pt-5"
    ref="listWrapper"
    @scroll="handleNotificationListScroll"
  >
    <div class="px-4" style="width:100%; max-width:800px">
      <!-- 게시판 제목 -->
      <v-row class="cr-title-row ma-0">
        <v-col cols="12" class="cr-title-wrapper mx-auto d-flex align-center">
          <div class="font-weight-bold" v-text="$t('board.공지사항')"></div>
          <v-spacer></v-spacer>
        </v-col>
      </v-row>
      <!-- 리스트 -->
      <v-row class="cr-list-wrapper ma-0">
        <Card ref="cardView" :posts="getNoticeList" />
      </v-row>
      <!-- 맨 위로 버튼 -->
      <v-btn
        v-show="showMoveTopBtn"
        fab
        dark
        color="primary"
        fixed
        right
        bottom
        small
        class="mr-4 cr-move-top-btn"
        @click="goToTop(true)"
      >
        <v-icon dark>mdi-page-last</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>

<script>
import { mapActions, mapGetters } from "vuex";
import Card from "@/board/views/components/main/list/Card";

export default {
  components: { Card },
  data: () => ({
    posts: [],
    showMoveTopBtn: false,
    // 페이지를 불러오는 중인지 여부 (중복 조회 방지)
    isLoading: false
  }),
  computed: {
    ...mapGetters("boardPost", ["getNoticeList"]),
    page() {
      return this.getNoticeList.page;
    },
    totalPage() {
      return this.getNoticeList.totalPage;
    }
  },
  watch: {
    // 현재페이지가 0으로 초기화될 경우
    page(n, p) {
      if (n === p) return;
      if (n === 0) {
        this.goToTop(false);
      }
    }
  },
  methods: {
    ...mapActions("boardPost", ["loadNoticeList"]),
    /** 무한 스크롤 정의*/
    handleNotificationListScroll(e) {
      const { scrollHeight, scrollTop, clientHeight } = e.target;
      this.showMoveTopBtn = scrollTop > 0 ? true : false;
      const isAtTheBottom =
        scrollHeight - 50 < Math.ceil(scrollTop + clientHeight);
      // 일정 한도 밑으로 내려오면 함수 실행
      if (isAtTheBottom) this.handleLoadMore();
    },
    // 내려오면 api 호출하여 아래에 더 추가, total값 최대이면 호출 안함
    async handleLoadMore() {
      if (this.page < this.totalPage && !this.isLoading) {
        this.isLoading = true;
        await this.loadNoticeList({
          page: this.page + 1
        });
        this.isLoading = false;
      }
    },
    // 스크롤을 맨위로 올리고 싶을 때
    goToTop(isSmooth) {
      this.$refs["listWrapper"].scroll({
        top: 0,
        behavior: isSmooth ? "smooth" : "auto"
      });
    }
  }
};
</script>
