<template>
  <v-row class="cr-post-box ma-0">
    <v-col cols="12">
      <v-hover v-slot="{ hover }">
        <v-card
          outlined
          :elevation="hover ? 1 : 0"
          class="cr-add-box mx-auto"
          @click="openAddPostDialog"
        >
          <v-card-text class="pa-3">
            <v-list-item dense>
              <v-list-item-avatar color="light-blue lighten-5">
                <v-avatar>
                  <v-icon color="primary" v-text="'mdi-plus'"></v-icon>
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-content
                :class="postAddTitleCls"
                v-text="$t('board.77')"
              >
              </v-list-item-content>
              <!-- 임시 제거 꼭 필요한 내용이 아니라면 삭제
              <v-list-item-action>
                <div
                  class="add-btn d-flex align-center justify-center primary white--text"
                  v-text="$t('board.작성')"
                ></div>
              </v-list-item-action>
              -->
            </v-list-item>
          </v-card-text>
        </v-card>
      </v-hover>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import i18n from "@/_locales";

export default {
  computed: {
    ...mapGetters("board", ["getActiveItems"]),
    postAddTitleCls() {
      return `text-${this.$vuetify.breakpoint.smAndDown ? "subtitle-1" : "h6"}`;
    }
  },
  methods: {
    ...mapMutations("boardDialog", ["SET_DIALOG"]),
    ...mapActions("snackbar", ["openSnackbar"]),
    // 게시글 작성 다이얼로그 open
    openAddPostDialog() {
      const [activeItem = {}] = this.getActiveItems;
      if (activeItem.id > 0) {
        if (activeItem.isDeleted) {
          return this.openSnackbar({
            message: i18n.t("board.78")
          });
        }
        if (activeItem.userPrivilege == "READ") {
          return this.openSnackbar({
            type: "ERROR",
            message: i18n.t("board.8")
          });
        }
      }
      this.SET_DIALOG({
        title: i18n.t("board.게시글"),
        type: "addPost"
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.cr-post-box ::v-deep {
  .cr-add-box {
    .v-list-item__action {
      .add-btn {
        width: 66px;
        height: 40px;
      }
    }
  }
}
</style>
