<template>
  <v-row class="cr-attach-wrapper" no-gutters>
    <v-col
      v-if="getTotalCount > 0"
      class="cr-btn-wrapper d-flex mb-3"
      cols="12"
    >
      <v-btn
        text
        outlined
        tile
        @click="openFileInput"
        v-text="$t('board.내_PC')"
      >
      </v-btn>
      <v-btn
        v-if="getItems.length > 0"
        text
        plain
        color="red"
        @click="deleteAllFiles"
        v-text="$t('board.전체_삭제')"
      >
      </v-btn>
      <v-spacer></v-spacer>
      <div class="d-flex align-end text-caption">
        <span v-html="$t('board.35', { value: getItems.length })"> </span>
        <span>({{ byteCalculation(getTotalSize) }})</span>
      </div>
    </v-col>
    <v-col
      v-else
      cols="12"
      class="cr-btn-wrapper d-flex align-center justify-center"
      style="border: 1px solid rgba(0, 0, 0, 0.12); height:40px;"
    >
      <div>
        <v-icon small class="mr-1">
          mdi-plus-box-multiple-outline
        </v-icon>
        <a href="#" @click.prevent="openFileInput" v-text="$t('board.내_PC')" />
        {{ $t("board.36") }}
      </div>
    </v-col>

    <AttachList v-show="showAttachList && getTotalCount > 0" />
    <input
      multiple
      v-show="false"
      type="file"
      ref="fileInput"
      @change="changeFileInput"
    />
    <FileUploader />
  </v-row>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import AttachList from "./AttachList";
import FileUploader from "./FileUploader";

import { byteCalculation } from "@/commons/utils/byteCalculation";
export default {
  data: () => ({
    counter: 0
  }),
  components: { AttachList, FileUploader },
  props: {
    showAttachList: {
      default: true,
      type: Boolean,
      description: "첨부 파일 리스트 보여 줄지 여부"
    }
  },
  computed: {
    ...mapGetters("boardFile", ["getTotalCount", "getTotalSize", "getItems"])
  },
  methods: {
    ...mapMutations("boardFile", ["UPLOAD_RESET"]),
    ...mapActions("boardFile", ["setFiles", "deleteAllFiles"]),

    byteCalculation(size) {
      return byteCalculation(size);
    },

    openFileInput() {
      this.$refs.fileInput.value = null;
      this.$refs.fileInput.click();
    },
    changeFileInput(e) {
      const files = e.target.files;
      if (!files) return;
      this.addFiles(files);
    },
    // vuex에 file저장
    addFiles(files) {
      this.setFiles(files);
      this.$emit("update:showAttachList", true);
    }
  }
};
</script>
