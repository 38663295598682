<template>
  <!-- 로딩중 -->
  <div v-if="loading" class="d-flex justify-center">
    <v-progress-circular :size="50" :width="4" color="grey" indeterminate />
  </div>
  <v-list v-else-if="list.length > 0" class="cr-search-list pa-0" outlined>
    <v-list-item
      v-for="(item, index) in list"
      :key="item.id"
      class="cr-list-element d-block pa-0"
    >
      <div
        class="d-flex align-start py-2 px-4"
        @click="onClickRow({ postId: item.postId, replyId: item.id })"
      >
        <!-- 프로필 아이콘-->
        <div class="py-1">
          <v-list-item-avatar size="35" class="mr-3">
            <div class="cr-reply-avatar">{{ avatar(item.userName) }}</div>
          </v-list-item-avatar>
        </div>
        <!-- 제목 내용 -->
        <div class="overflow-hidden" style="width:100%">
          <v-list-item-content class="reply_wrapper">
            <!-- 타이틀 -->
            <v-list-item-title
              class="reply_user d-flex align-center mb-1 font-weight-bold"
            >
              <!-- 작성자 -->
              <UserName
                :userId="item.userId"
                :userName="item.userName"
                :userEmail="item.userEmail"
                class="mr-3"
              />
              <!-- 날짜 -->
              <span class="mr-1 text-caption grey--text text--darken-2">
                {{ convertDateFromMillis(item.createdTimeMillis) }}
              </span>
            </v-list-item-title>
            <!-- 댓글 본문 영역 -->
            <div class="overflow-hidden">
              <!-- 내용 -->
              <v-list-item-subtitle
                class="reply_content"
                v-html="item.replyContent"
              />
              <!-- 첨부파일 -->
              <div v-if="item.attach" class="pa-2 mt-2 rounded attach-wrapper">
                <FileItem :file="item.attach" />
              </div>
            </div>
          </v-list-item-content>
        </div>
      </div>
      <v-divider v-if="index < list.length - 1" :key="index" class="mx-4" />
    </v-list-item>
  </v-list>
  <!-- NO DATA -->
  <div
    v-else
    class="text-subtitle-2 grey--text text--darken-1"
    v-text="'검색 결과가 없습니다.'"
  />
</template>

<style lang="scss" scoped>
.cr-search-list::v-deep {
  .cr-list-element:hover {
    background: rgb(106 143 255 / 10%);
    cursor: pointer;
  }
  .cr-hlt {
    background: beige;
    color: black;
    font-style: normal;
  }
  .cr-reply-avatar {
    line-height: 35px;
    width: 35px;
    height: 35px;
    background: #90a4ae;
    color: white;
  }
  // 댓글 내용 부분
  .reply_content {
    // 칸이 넘어갔을때 ...표시 방지
    overflow: visible;
    white-space: normal;
    color: rgba(0, 0, 0, 0.7) !important;
    line-height: 1.4;
  }
}
</style>

<script>
import moment from "moment";
import { mapActions, mapMutations } from "vuex";
import UserName from "@/board/views/components/common/item/UserName";

export default {
  components: { UserName },
  props: {
    listData: {
      type: Object,
      default: () => {},
      description: "게시글 목록과 데이타"
    }
  },
  mounted() {},
  data: () => ({}),
  watch: {},
  computed: {
    list() {
      return this.listData.list;
    },
    loading() {
      return this.listData.loading;
    }
  },
  methods: {
    ...mapActions("boardPost", ["loadPost"]),
    ...mapActions("boardReply", ["loadReplyListById"]),
    ...mapMutations("boardDialog", ["SET_DIALOG"]),
    avatar(userName) {
      // 하이라이트 태그 제거
      let dom = document.createElement("div");
      dom.innerHTML = userName;
      return dom.innerText.slice(0, 1).toUpperCase();
    },
    // 날짜 포맷
    convertDateFromMillis(millis, format = "YYYY.MM.DD (dd) HH:mm") {
      return moment(millis).format(format);
    },
    // 댓글 클릭시
    async onClickRow({ postId, replyId }) {
      // 다이얼로그 호출
      this.SET_DIALOG({
        type: "viewPost",
        params: { postId, replyId }
      });
    }
  }
};
</script>
