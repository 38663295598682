<template>
  <div :class="boardMainCls">
    <Banner v-if="mainShowType != 'CONFIG' && nowBoardIsDeleted"></Banner>
    <BoardConfig v-if="mainShowType == 'CONFIG'"></BoardConfig>
    <BoardView v-else-if="mainShowType == 'VIEW'"></BoardView>
    <BoardNoticeList v-else-if="mainShowType == 'NOTICE'"></BoardNoticeList>
    <BoardTrashList v-else-if="mainShowType == 'TRASH'"></BoardTrashList>
    <BoardSearchMain v-else-if="mainShowType == 'SEARCH'"></BoardSearchMain>
    <BoardList v-else></BoardList>
    <Dialog></Dialog>

    <BoardTutorial />
  </div>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from "vuex";
import BoardTutorial from "@/commons/views/tutorial/BoardTutorial";

import Banner from "@/board/views/components/main/Banner";
import BoardConfig from "@/board/views/components/main/BoardConfig";
import BoardView from "@/board/views/components/main/BoardView";
import BoardList from "@/board/views/components/main/BoardList";
import BoardNoticeList from "@/board/views/components/main/BoardNoticeList";
import BoardTrashList from "@/board/views/components/main/BoardTrashList";
import BoardSearchMain from "@/board/views/components/main/search/BoardSearchMain";
import Dialog from "@/board/views/components/main/Dialog";
export default {
  components: {
    BoardTutorial,
    Banner,
    BoardConfig,
    BoardView,
    BoardList,
    BoardNoticeList,
    BoardTrashList,
    BoardSearchMain,
    Dialog
  },
  async mounted() {
    this.setShowSplash(false);
  },
  destroyed() {
    // 게시판관련 store 초기화
    this.INIT_BOARD_STATE();
    this.INIT_CONFIG_STATE();
    this.CLOSE_DIALOG();
    this.INIT_POST_STATE();
    this.INIT_ROUTE_STATE();
  },
  computed: {
    ...mapGetters("board", ["nowBoardIsDeleted", "getBoardById"]),
    ...mapGetters("boardRoute", ["getRouteListInfo"]),
    mainShowType() {
      const { id } = this.getRouteListInfo;
      switch (this.$route.name) {
        case "board_config":
          return "CONFIG";
        case "board_post_view":
          return "VIEW";
        case "board_search":
          return "SEARCH";
        default:
          if (this.getBoardById(id)?.boardType === "TRASH") return "TRASH";
          if (this.getBoardById(id)?.boardType === "NOTICE") return "NOTICE";
          return "LIST";
      }
    },
    boardMainCls() {
      return `cr-board-main${this.nowBoardIsDeleted ? " deleted-board" : ""}`;
    }
  },
  methods: {
    ...mapMutations("board", ["INIT_BOARD_STATE"]),
    ...mapMutations("boardConfig", ["INIT_CONFIG_STATE"]),
    ...mapMutations("boardDialog", ["CLOSE_DIALOG"]),
    ...mapMutations("boardPost", ["INIT_POST_STATE"]),
    ...mapMutations("boardRoute", ["INIT_ROUTE_STATE"]),
    ...mapActions("splash", ["setShowSplash"])
  }
};
</script>

<style lang="scss" scoped>
::-webkit-scrollbar-thumb {
  border: solid #f1f1f1;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.1);
}
.v-tooltip__content {
  padding: 4px 12px;
}
.v-application {
  font-family: "Nanum Gothic", sans-serif !important;
}
.v-text-field__details {
  display: none;
}
.cr-board-main {
  background: #f1f1f3 !important;
  position: relative;
  height: 100%;
  width: 100%;
  &.deleted-board {
    .cr-list-main ::v-deep {
      height: calc(100% - 52px);
    }
  }

  .cr-list-main ::v-deep {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    display: block;

    &.cr-setting {
      padding-right: 320px;
    }

    .cr-board-tabbar {
      .v-tabs ::v-deep {
        .v-tab {
          max-width: 160px;
        }
      }
    }
    .cr-move-top-btn {
      left: 45%;
      .v-icon {
        transform: rotate(-90deg);
      }
    }
  }
}
</style>
