<template>
  <!-- Custom Event    -->
  <!-- @onPanelTitleClick = 패널 제목 클릭시 -->
  <!-- @onPanelListClick  = 패널 리스트 클릭시 -->
  <v-card outlined elevation="0" class="cr-panel">
    <!-- 타이틀 -->
    <v-card-title class="cr-panel-title py-1 d-flex align-center">
      <!-- 펼치기 토글 버튼 -->
      <v-btn
        icon
        color="white"
        class="mr-2"
        v-if="isMobile"
        @click="toggleIsUnfold"
      >
        <v-icon>
          {{ isUnfold ? "mdi-menu-down" : "mdi-menu-right" }}
        </v-icon>
      </v-btn>

      <div
        class="mr-auto text-body-1 font-weight-bold"
        v-text="panelOption.title"
      ></div>
      <!-- 더보기 버튼 -->
      <v-btn
        class="cr-tutorial-board-side-more"
        icon
        color="white"
        @click="titleClick"
      >
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text v-show="isUnfold" class="px-0 py-1">
      <v-list v-if="list.length > 0" two-line class="py-0">
        <v-list-item
          v-for="(post, index) in list"
          :key="index"
          class="px-4"
          @click.stop="onPanelListClick(post.id)"
        >
          <v-list-item-content class="py-0">
            <!-- 리스트 제목 -->
            <div
              class="text-subtitle overflow-hidden text--black
              d-flex justify-start align-center "
            >
              <div
                class="text-subtitle-1 cr-ellipsis"
                :class="loading || post.seen ? '' : 'font-weight-bold'"
              >
                {{ post.title }}
              </div>
              <!-- 읽음 여부 -->
              <v-icon v-if="!loading && !post.seen" color="orange">
                mdi-alpha-n-box
              </v-icon>
            </div>
            <!-- 리스트 부제 -->
            <v-list-item-subtitle
              class="d-flex text-caption font-weight-regular"
              :class="loading || post.seen ? '' : 'font-weight-bold'"
            >
              <UserName
                :userId="post.userId"
                :userName="post.userName"
                :userEmail="post.userEmail"
              />
              <div
                class="mx-2"
                style="border-right:1px solid grey; margin-top: 2px; margin-bottom: 2px;"
              ></div>
              <div>{{ convertDateFromMillis(post.updatedTimeMillis) }}</div>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <!-- NO DATA -->
      <div
        v-else
        class="d-flex justify-center py-1"
        v-text="panelOption.noData"
      ></div>
    </v-card-text>
  </v-card>
</template>

<style lang="scss" scoped>
.cr-panel {
  .cr-panel-title {
    background-color: var(--v-primary-base);
    color: white;
  }
  .cr-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import UserName from "@/board/views/components/common/item/UserName";
import i18n from "@/_locales";

export default {
  components: { UserName },

  props: {
    type: {
      type: String,
      default: "",
      description: "패널 타입 (notice, ...) "
    }
  },
  mounted() {
    // 패널 펼침 여부 지정
    this.isUnfold = !this.isMobile;
  },
  data: () => ({
    isUnfold: true,
    // 타입별 옵션 데이타
    panelData: {
      notice: {
        title: i18n.t("board.공지사항"),
        color: "#6a8fff",
        icon: "mdi-pin",
        noData: i18n.t("board.149")
      }
    }
  }),
  watch: {
    // 패널 펼침 여부 반응형
    isMobile(n) {
      this.isUnfold = !n;
    }
  },
  methods: {
    convertDateFromMillis(millis) {
      return moment(millis).format("YYYY.MM.DD (dd) HH:mm");
    },
    // 제목 클릭시 (부모 컴포넌트에서 이벤트 핸들링)
    titleClick() {
      this.$emit("onPanelTitleClick", this.type);
    },
    // 리스트 아이템 클릭시 (부모 컴포넌트에서 이벤트 핸들링)
    onPanelListClick(postId) {
      this.$emit("onPanelListClick", this.type, postId);
    },
    toggleIsUnfold() {
      this.isUnfold = !this.isUnfold;
    }
  },
  computed: {
    ...mapGetters("boardPost", ["getNoticeList"]),
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
        case "sm":
          return true;
        default:
          return false;
      }
    },
    // 타입별 리스트 목록
    list() {
      switch (this.type) {
        case "notice":
          return this.getNoticeList.list.slice(0, 5);
        default:
          return [];
      }
    },
    loading() {
      switch (this.type) {
        case "notice":
          return this.getNoticeList?.loading;
        default:
          return [];
      }
    },
    panelOption() {
      const type = this.type;
      return this.panelData[type];
    }
  }
};
</script>
