<template>
  <v-card-text class="py-0">
    <BoardDialog
      v-if="dialogType === 'addBoard' || dialogType === 'editBoard'"
      v-bind="$props"
      v-on="$listeners"
    />
    <BoardDetail
      v-if="dialogType === 'boardDetail'"
      v-bind="$props"
      v-on="$listeners"
    />
    <PostWriteDialog
      v-if="dialogType === 'addPost' || dialogType === 'editPost'"
      v-bind="$props"
      v-on="$listeners"
    ></PostWriteDialog>
    <PostViewDialog
      v-if="dialogType === 'viewPost' || dialogType === 'viewPostExpand'"
      v-bind="$props"
      v-on="$listeners"
    />
    <PostMoveDialog
      v-if="dialogType === 'movePost'"
      v-bind="$props"
      v-on="$listeners"
    />
    <DeleteBoardDialog
      v-if="dialogType === 'deleteBoard'"
      v-bind="$props"
      v-on="$listeners"
    />
  </v-card-text>
</template>

<script>
import { mapGetters } from "vuex";

import BoardDialog from "@/board/views/components/dialog/BoardDialog";
import BoardDetail from "@/board/views/components/dialog/BoardDetail";
import PostWriteDialog from "@/board/views/components/dialog/PostWriteDialog";
import PostViewDialog from "@/board/views/components/dialog/PostViewDialog";
import DeleteBoardDialog from "@/board/views/components/dialog/DeleteBoardDialog";
import PostMoveDialog from "@/board/views/components/dialog/PostMoveDialog";
export default {
  components: {
    BoardDialog,
    BoardDetail,
    PostWriteDialog,
    PostViewDialog,
    DeleteBoardDialog,
    PostMoveDialog
  },
  props: {
    left: {
      default: false,
      type: Boolean,
      description: "좌측 버튼"
    },
    leftDisabled: {
      default: false,
      type: Boolean,
      description: "좌측 버튼 Disabled 여부"
    },
    confirm: {
      default: false,
      type: Boolean,
      description: "확인 버튼"
    },
    confirmDisabled: {
      default: false,
      type: Boolean,
      description: "확인 버튼 Disabled 여부"
    }
  },
  computed: {
    ...mapGetters("boardDialog", ["dialogType"])
  }
};
</script>
