<template>
  <v-row class="board-dialog-wrapper ma-0 py-5 mx-auto">
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <!-- 게시판 명 -->
    <v-col cols="12">
      <div
        class="cr-title mb-2 d-flex align-start"
        v-text="$t('board.게시판명')"
      >
        <v-icon
          color="red accent-3"
          class="ml-1"
          :size="10"
          v-text="'mdi-asterisk'"
        />
      </div>
      <div>
        <v-text-field
          dense
          outlined
          height="50"
          hide-details
          autocomplete="off"
          tabindex="1"
          ref="newName"
          :placeholder="$t('board.43')"
          v-model="name"
        ></v-text-field>
      </div>
    </v-col>
    <!-- 게시판 설명 -->
    <v-col cols="12">
      <div class="cr-title mb-2" v-text="$t('board.설명')"></div>
      <div>
        <v-textarea
          outlined
          no-resize
          height="150"
          hide-details
          tabindex="2"
          :placeholder="$t('board.44')"
          v-model="description"
        ></v-textarea>
      </div>
    </v-col>
    <!-- 관리자 설정 -->
    <v-col cols="12" v-if="!isChildBoard && !isAddGroup">
      <div class="cr-title mb-2" v-text="$t('board.45')"></div>
      <div>
        <Privilege
          :type="'admin'"
          :placeholder="$t('board.46')"
          :members="admins"
          :deleteList.sync="deleteAdminList"
          @updateMember="updateMember"
        />
      </div>
    </v-col>
    <v-col
      cols="12"
      class="py-0"
      v-if="getParams.mode == 'SAVE' || dialogType == 'editBoard'"
    >
      <v-divider></v-divider>
    </v-col>
    <!-- 게시판 공개 여부 -->
    <v-col cols="12" class="cr-memberType-wrapper py-0">
      <v-list-item class="pa-0">
        <v-list-item-content>
          <v-list-item-title class="mb-1 text-subtitle-2">
            <span v-text="$t('board.공개여부')"></span>
          </v-list-item-title>
          <v-list-item-subtitle
            v-if="disableToggleMemberType"
            v-text="$t('board.135')"
            class="blue--text text--lighten-2"
          />
          <v-list-item-subtitle
            v-else
            v-text="memberType ? $t('board.47') : $t('board.48')"
          />
        </v-list-item-content>
        <v-list-item-action>
          <v-switch
            :disabled="disableToggleMemberType"
            hide-details
            inset
            v-model="memberType"
          />
        </v-list-item-action>
      </v-list-item>
    </v-col>
    <v-col cols="12" class="py-0">
      <v-divider></v-divider>
    </v-col>
    <!-- 구성원 -->
    <v-row v-if="!memberType" class="ma-0 px-3">
      <v-col class="pa-7 cr-sub-wrapper" cols="12">
        <Privilege
          :type="'member'"
          :title="$t('board.구성원')"
          :placeholder="$t('board.49')"
          :members="members"
          :deleteList.sync="deleteMemberList"
          @updateMember="updateMember"
        />
      </v-col>
    </v-row>

    <!-- 닫기 / 저장 -->
    <v-col cols="12" class="mx-auto text-center">
      <v-btn
        large
        text
        outlined
        class="mr-4"
        width="120"
        @click="CLOSE_DIALOG"
        v-text="$t('board.닫기')"
      >
      </v-btn>
      <v-btn
        large
        depressed
        outlined
        color="accent"
        width="120"
        :disabled="confirmDisabled"
        @click="confirm = true"
        v-text="$t('common.저장')"
      >
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import Privilege from "@/board/views/components/dialog/privilege/Privilege";

import { isBlank } from "@/commons/utils/validation";
import { findPrivileges } from "@/board/api/board.api";
import i18n from "@/_locales";

export default {
  components: { Privilege },
  data: () => ({
    // 확인 버튼
    confirm: false,
    overlay: false,

    name: "",
    description: "",
    memberType: true,
    boardId: 0,
    groupId: 0,
    childCount: 0,
    admins: [],
    deleteAdminList: [],
    members: [],
    deleteMemberList: []
  }),
  async mounted() {
    setTimeout(() => {
      this.$refs.newName.focus();
    }, 0);
    if (this.dialogType == "addBoard") {
      const { mode, parentBoard } = this.getParams;
      if (mode === "ADD_GROUP") {
        // 하위 게시판 추가일 경우
        const { data = {}, status } = await findPrivileges(parentBoard.id);
        const type = status === 200 ? "SUCCESS" : "ERROR";
        if (type == "ERROR")
          return this.openSnackbar({
            type,
            message: i18n.t("board.56")
          });

        // 상위 게시판 관리자 불러옴
        const { admins } = data;

        admins.forEach(m => {
          this.admins.push({
            memberId: m.memberId,
            memberName: m.memberName,
            privilegeType: m.privilegeType,
            isNew: false,
            isUpdated: false,
            isSuperAdmin: parentBoard.userId == m.memberId
          });
        });
      } else {
        const member = {
          memberId: this.getUserInfo.id,
          memberName: this.getUserInfo.accountName,
          privilegeType: "ADMIN",
          isNew: false,
          isUpdated: false,
          isSuperAdmin: true
        };
        this.admins.push(member);
      }
    }
    // 만약 상위게시판 수정일 경우 권한, 권한멤버 조회
    if (this.dialogType == "editBoard") {
      this.overlay = true;
      const { board } = this.getParams;
      // 기본정보 입력
      this.name = board.name;
      this.description = board.description;
      this.boardId = board.id;
      this.groupId = board.groupId;
      this.childCount = board.childCount;

      // 멤버 권한
      const { data = {}, status } = await findPrivileges(board.id);
      const type = status === 200 ? "SUCCESS" : "ERROR";
      if (type == "ERROR")
        return this.openSnackbar({
          type,
          message: i18n.t("board.56")
        });

      // 권한이 비공개일 경우 기존 권한멤버를 넣어준다.
      const { memberType, members, admins } = data;
      // 기본정보 - 권한 입력
      this.memberType = memberType == "ANY" ? true : false;

      admins.forEach(m => {
        this.admins.push({
          memberId: m.memberId,
          memberName: m.memberName,
          privilegeType: m.privilegeType,
          isNew: false,
          isUpdated: false,
          isSuperAdmin: board.userId == m.memberId
        });
      });

      if (memberType == "MEMBER") {
        members.forEach(m => {
          this.members.unshift({
            memberId: m.memberId,
            memberName: m.memberName,
            privilegeType: m.privilegeType,
            isNew: false,
            isUpdated: false
          });
        });
      }

      this.overlay = false;
    }
  },
  watch: {
    // 확인버튼 클릭
    async confirm(value) {
      if (value) {
        await this.save();
        this.confirm = false;
      }
    }
  },
  computed: {
    ...mapGetters("boardDialog", ["dialogType", "getParams"]),
    ...mapGetters("auth", ["getUserInfo"]),
    confirmDisabled() {
      return isBlank(this.name) || this.confirm;
    },
    disableToggleMemberType() {
      // 하위 게시판이 존재하는 경우 공개범위 지정 불가능
      if (this.childCount > 0) return true;
      return false;
    },
    isChildBoard() {
      return this.boardId !== this.groupId;
    },
    isAddGroup() {
      return this.getParams.mode === "ADD_GROUP";
    }
  },
  methods: {
    ...mapMutations("boardDialog", ["CLOSE_DIALOG"]),
    ...mapActions("snackbar", ["openSnackbar"]),
    ...mapActions("board", ["addBoard", "updateBoard"]),

    // 게시판 저장
    async save() {
      const { name } = this;
      this.name = name.trim();
      if (isBlank(this.name)) {
        this.$refs.newName.focus();
        return this.openSnackbar({
          message: i18n.t("board.57")
        });
      }
      // 게시판 등록, 수정 분리
      if (this.dialogType == "editBoard") {
        await this.update();
      } else {
        await this.add();
      }
      this.CLOSE_DIALOG();
    },
    // 게시판 추가
    async add() {
      const privilegeObj = {
        memberType: this.memberType ? "ANY" : "MEMBER",
        members: this.members,
        admins: this.admins
      };

      await this.addBoard({
        name: this.name,
        description: this.description,
        mode: this.getParams.mode,
        parentId: this.getParams.parentBoard
          ? this.getParams.parentBoard.id
          : 0,
        privilege: privilegeObj
      });
    },
    // 게시판 수정
    async update() {
      // 추가 or 수정될 멤버 리스트
      const updateMemberList = this.members.filter(
        ({ isNew, isUpdated }) => isUpdated == true || isNew == true
      );
      // 추가 할 관리자 멤버 리스트
      const addAdminList = this.admins.filter(({ isNew }) => isNew == true);

      const privilegeObj = {
        memberType: this.memberType ? "ANY" : "MEMBER",
        addAdminList,
        deleteAdminList: this.deleteAdminList,
        updateMemberList,
        // 삭제될 멤버 리스트
        deleteMemberList: this.deleteMemberList
      };

      await this.updateBoard({
        id: this.getParams.board.id,
        name: this.name,
        description: this.description,
        privilege: privilegeObj
      });
    },
    // 사용자/관리자 변경 이벤트 리스너
    updateMember(type, item) {
      // 중복확인
      const memberCheck = this.members.filter(
        ({ memberId }) => memberId == item.userId
      );
      const adminCheck = this.admins.filter(
        ({ memberId }) => memberId == item.userId
      );
      const { length: memberExist } = memberCheck;
      const { length: adminExist } = adminCheck;

      let message = "";
      let trigger = false;

      if (type === "member") {
        // 사용자 변경일 경우
        if (memberExist + adminExist > 0) trigger = true;
        message =
          memberExist > 0
            ? i18n.t("board.41") // 이미 추가됨
            : adminExist > 0
            ? i18n.t("board.136") // 이미 관리자
            : "";
      } else {
        // 관리자 변경일 경우
        if (adminExist > 0) trigger = true;
        message = i18n.t("board.41");
        // 사용자 목록에 이미 추가된 경우 제거
        if (memberExist > 0) {
          this.members = this.members.filter(m => m.memberId != item.userId);
        }
      }

      if (trigger) {
        return this.openSnackbar({
          message,
          type: "VALIDATION"
        });
      }

      // 2. 삭제목록 멤버일 경우 -> 다시 되돌려 준다.
      const deleteList =
        type == "member" ? this.deleteMemberList : this.deleteAdminList;
      const index = deleteList.findIndex(m => m.memberId == item.userId);
      if (index >= 0) {
        const target = deleteList.splice(index, 1);
        return type == "member"
          ? (this.members = [...target, ...this.members])
          : (this.admins = [...this.admins, ...target]);
      }

      const member = {
        memberId: item.userId,
        memberName: item.name,
        privilegeType: type == "member" ? "READ_WRITE" : "ADMIN",
        isNew: true,
        isUpdated: false
      };

      type == "member"
        ? this.members.unshift(member)
        : this.admins.push(member);
    }
  }
};
</script>

<style lang="scss" scoped>
.board-dialog-wrapper ::v-deep {
  max-width: 600px;
  .cr-title {
    color: rgba(0, 0, 0, 0.87);
  }
  .cr-memberType-wrapper,
  .v-input.v-textarea {
    .v-input__control {
      .v-input__slot {
        padding-right: 13px;
      }
      textarea {
        margin-bottom: 10px;
      }
    }
  }
  .cr-sub-wrapper {
    background-color: #f5f8fa !important;
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
    .cr-no-data {
      text-align: center;
    }
  }
}
</style>
