<template>
  <v-dialog
    scrollable
    persistent
    no-click-animation
    :width="width"
    :max-width="width"
    @input="CLOSE_DIALOG"
    @click:outside="onClickOutSide"
    :fullscreen="fullscreen"
    v-model="showDialog"
    :retain-focus="false"
  >
    <v-card>
      <div v-if="!contentOnly">
        <v-card-title
          v-if="isTypeBoard"
          class="py-6"
          style="border-bottom: thin solid rgba(0, 0, 0, 0.12);"
        >
          <div class="mx-auto text-h6 font-weight-bold" v-text="dialogTitle" />
        </v-card-title>
        <v-card-title v-else class="py-4">
          <span class="text-h6 font-weight-bold " v-text="dialogTitle" />
        </v-card-title>
      </div>
      <DialogContent
        :class="contentOnly ? 'pa-0' : ''"
        :left.sync="left"
        :leftDisabled.sync="leftDisabled"
        :confirm.sync="confirm"
        :confirmDisabled.sync="confirmDisabled"
      ></DialogContent>
      <v-card-actions
        v-if="!isTypeBoard && !contentOnly"
        class="py-4 px-6 d-flex justify-center"
      >
        <!-- 좌측 버튼 -->
        <v-btn
          v-if="getLeftBtn.show"
          class="px-4 cr-left-btn"
          text
          :disabled="leftDisabled"
          @click="left = true"
          >{{ getLeftBtn.text }}
        </v-btn>

        <!-- 필수항목 -->
        <div v-if="isShowRequired">
          <v-icon
            color="red accent-3"
            class="ml-1"
            size="10"
            v-text="'mdi-asterisk'"
          />
          <span class="caption ml-1" v-text="$t('board.102')"></span>
        </div>

        <v-spacer></v-spacer>

        <!-- 취소버튼 -->
        <v-btn v-if="getCancelBtn.show" text outlined @click="CLOSE_DIALOG">
          {{ getCancelBtn.text }}
        </v-btn>
        <!-- 확인버튼 -->
        <v-btn
          v-show="getConfirmBtn.show"
          outlined
          color="accent"
          :disabled="confirmDisabled"
          @click="confirm = true"
        >
          {{ getConfirmBtn.text }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import DialogContent from "@/board/views/components/dialog/index";

export default {
  components: { DialogContent },
  data: () => ({
    // 좌측 버튼
    left: false,
    // 좌측 버튼 Disabled 여부
    leftDisabled: false,
    // 확인 버튼
    confirm: false,
    // 확인 버튼 Disabled 여부
    confirmDisabled: false
  }),
  watch: {
    dialogType() {
      this.confirm = false;
      this.confirmDisabled = false;
      this.left = false;
      this.leftDisabled = false;
    }
  },
  computed: {
    ...mapGetters("boardDialog", [
      "showDialog",
      "dialogTitle",
      "dialogType",
      "getLeftBtn",
      "isShowRequired",
      "getCancelBtn",
      "getConfirmBtn"
    ]),
    width() {
      switch (this.dialogType) {
        case "addPost":
        case "editPost":
          return "800";
        case "viewPost":
          return "670";
        case "viewPostExpand":
          return "1200";
        case "deleteBoard":
          return "500";
        case "movePost":
          return "400";
        default:
          return "600";
      }
    },
    isTypeBoard() {
      return this.dialogType == "addBoard" || this.dialogType == "editBoard";
    },
    contentOnly() {
      return (
        this.dialogType == "viewPost" || this.dialogType == "viewPostExpand"
      );
    },
    fullscreen() {
      if (this.isTypeBoard) return true;
      if (this.dialogType != "addPost" && this.dialogType != "editPost")
        return false;
      return this.$vuetify.breakpoint.smAndDown;
    }
  },
  methods: {
    ...mapMutations("boardDialog", ["CLOSE_DIALOG"]),
    onClickOutSide() {
      if (this.contentOnly) this.CLOSE_DIALOG();
    }
  }
};
</script>
