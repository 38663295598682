<template>
  <!-- 게시글 있을 경우 -->
  <v-row
    v-if="posts.loading || postList.length > 0"
    class="cr-post-wrapper ma-0 mb-8"
  >
    <v-col cols="12">
      <v-card class="mx-auto" elevation="0" outlined>
        <!-- 로딩중일 경우 -->
        <v-progress-linear
          :active="showLoading"
          :indeterminate="showLoading"
          absolute
          top
          color="primary"
        />
        <v-list class="cr-search-list pa-0">
          <v-list-item
            v-for="item in postList"
            :key="item.id"
            class="d-block px-0"
          >
            <v-list-item-content
              class="cr-list-element px-4 py-3"
              @click="postView(item)"
            >
              <!-- 제목 -->
              <v-list-item-title
                class="d-flex align-center black--text font-weight-bold mb-1"
              >
                <span v-html="item.title" class="mr-1 cr-title-ellipsis" />
                <!-- 중요 -->
                <v-icon v-if="item.isImportant" color="primary" size="19">
                  mdi-sticker
                </v-icon>
                <!-- 공지사항 -->
                <v-icon v-if="item.isNotice" color="primary" size="19">
                  mdi-pin
                </v-icon>
              </v-list-item-title>
              <!-- 본문 -->
              <v-list-item-subtitle class="mb-2">
                <div
                  v-text="getInnerText(item.content)"
                  class="cr-content-ellipsis"
                />
              </v-list-item-subtitle>
              <!-- 사용자 및 부가정보 -->
              <v-list-item-subtitle
                class="d-flex align-center justify-space-between"
              >
                <div class="d-flex align-center">
                  <!-- 사용자 -->
                  <UserName
                    :userId="item.userId"
                    :userName="item.userName"
                    :userEmail="item.userEmail"
                  />
                  <v-icon size="18">
                    mdi-chevron-right
                  </v-icon>
                  <!-- 게시판 -->
                  <div>
                    {{ boardName(item.boardId) }}
                  </div>
                  <!-- 비공개 게시판 아이콘 -->
                  <v-icon
                    v-if="boardType(item.boardId) === 'MEMBER'"
                    color="grey"
                    size="13"
                    style="margin-bottom: 2px;"
                  >
                    mdi-lock
                  </v-icon>
                  <v-divider
                    v-if="item.replyCount > 0 || item.fileCount > 0"
                    vertical
                    class="my-1 mx-2 grey"
                  />
                  <!-- 댓글 갯수 -->
                  <div
                    v-if="item.replyCount > 0"
                    class="d-flex align-center mr-2"
                  >
                    <v-icon size="19" class="mr-1" small>
                      mdi-message-reply-text-outline
                    </v-icon>
                    <span>
                      {{ item.replyCount }}
                    </span>
                  </div>
                  <!-- 첨부파일 갯수 -->
                  <div v-if="item.fileCount > 0" class="d-flex align-center">
                    <v-icon size="19" class="mr-1" small>
                      mdi-paperclip
                    </v-icon>
                    <span>
                      {{ item.fileCount }}
                    </span>
                  </div>
                </div>
                <!-- 날짜 -->
                <span v-html="convertDateFromMillis(item.updatedTimeMillis)" />
              </v-list-item-subtitle>
              <!-- 태그 -->
              <TagList v-if="item.tags.length > 0" :tags="item.tags" dense />
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-col>
    <v-col cols="12">
      <Pagination
        v-if="totalElements > 0"
        type="list"
        :page="page"
        :pageSize="10"
        :blockSize="5"
        :totalElements="totalElements"
        @pageClick="onPageClick"
      />
    </v-col>
  </v-row>
  <!-- 게시글 없을 경우 -->
  <NoData v-else />
</template>

<script>
import { mapGetters } from "vuex";
import { virtualBoardConverter } from "@/board/constant/boardType";
import Pagination from "@/commons/views/Pagination";
import NoData from "@/board/views/components/main/list/common/NoData";
import TagList from "@/board/views/components/common/TagList.vue";
import UserName from "@/board/views/components/common/item/UserName";
import i18n from "@/_locales";

import moment from "moment";
export default {
  components: { Pagination, NoData, TagList, UserName },
  props: {
    posts: {
      description: "게시글 리스트"
    }
  },
  data: () => ({
    // 로딩 해제 레이턴시 적용된 플래그
    showLoading: true
  }),
  watch: {
    page() {
      this.pageCalibrate();
    },
    totalPage() {
      this.pageCalibrate();
    },
    // 로딩 해제 레이턴시 적용
    loading(n) {
      if (n) {
        this.showLoading = n;
        return;
      }
      setTimeout(() => {
        this.showLoading = n;
      }, 150);
    }
  },
  mounted() {
    this.pageCalibrate();
    this.showLoading = this.loading;
  },
  computed: {
    ...mapGetters("boardRoute", ["getRouteListInfo"]),
    ...mapGetters("board", ["getBoardById"]),
    postList() {
      return this.posts?.list ?? [];
    },
    page() {
      return this.posts?.page ?? 0;
    },
    totalPage() {
      return this.posts?.totalPage ?? 0;
    },
    totalElements() {
      return this.posts?.totalElements ?? 0;
    },
    loading() {
      return this.posts?.loading ?? false;
    },
    showBoardName() {
      return this.getRouteListInfo.id < 0;
    }
  },
  methods: {
    // 날짜 포맷
    convertDateFromMillis(millis) {
      return moment(millis).format("YYYY.MM.DD (dd) HH:mm");
    },
    // HTML to Plain Text
    getInnerText(html) {
      let dom = document.createElement("div");
      dom.innerHTML = html;
      return dom.innerText;
    },
    postView(post) {
      const { id: boardId, actionObj } = this.getRouteListInfo;

      this.$router.push({
        name: "board_post_view",
        params: {
          postId: post.id,
          actionObj: JSON.stringify({
            ...actionObj,
            boardId: virtualBoardConverter(boardId)
          })
        }
      });
    },
    boardName(boardId) {
      return this.getBoardById(boardId)?.name ?? i18n.t("board.148");
    },
    boardType(boardId) {
      return this.getBoardById(boardId)?.memberType ?? "ANY";
    },
    // 최대 페이지보다 큰 페이지 요청시 페이지 보정
    pageCalibrate() {
      if (this.totalPage > 0 && this.page > this.totalPage) {
        this.onPageClick(this.totalPage);
      }
    },
    onPageClick(page) {
      // 현재 라우팅 파라미터에서 page 속성만 변경
      const { id: boardId, actionObj } = this.getRouteListInfo;
      this.$router.push({
        name: "board_list_action",
        params: {
          boardId: virtualBoardConverter(boardId),
          actionObj: JSON.stringify({
            ...actionObj,
            page
          })
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.cr-search-list::v-deep {
  .cr-list-element:hover {
    background: rgb(106 143 255 / 10%);
    cursor: pointer;
  }
  .cr-hlt {
    background: beige;
    color: black;
    font-style: normal;
  }
  .cr-title-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .cr-content-ellipsis {
    white-space: normal;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    line-height: 1.4;
  }
}
.cr-post-wrapper ::v-deep {
  width: 100%;
  .v-list {
    .v-list-item {
      border-bottom: thin solid rgba(0, 0, 0, 0.12);
    }
    .v-list-item:nth-last-child(1) {
      border-bottom: none;
    }
    .theme--light.v-list-item:focus::before {
      opacity: 0;
    }
  }
  .cr-no-data {
    max-width: 800px;
    height: 450px;
    .text-wrapper {
      text-align: center;
    }
  }
}
</style>
