<!--
  @showPreview : 미리보기 이벤트
-->
<template>
  <div
    :class="loading ? 'loading' : 'no-data'"
    @click.stop="showPreview"
    v-text="description"
  ></div>
</template>

<style lang="scss" scoped>
.no-data {
  height: 250px;
  width: 100%;
  background-color: grey;
  text-align: center;
  line-height: 250px;
  color: #fff;
  cursor: pointer;
  &:hover {
    background-color: #4c4c4c;
  }
}
.loading {
  height: 250px;
  width: 100%;
  background-color: grey;
  text-align: center;
  line-height: 250px;
  color: #fff;
  cursor: default;
}
</style>
<script>
import i18n from "@/_locales";

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
      description: "로딩중인지 여부"
    },
    link: {
      type: String,
      default: "",
      description: "입력된 URL"
    }
  },
  methods: {
    showPreview() {
      if (this.loading) return;
      this.$emit("showPreview");
    }
  },
  computed: {
    description() {
      if (this.loading) {
        return i18n.t("board.로딩중");
      } else if (this.link) {
        return i18n.t("board.156");
      } else {
        return i18n.t("board.139");
      }
    }
  }
};
</script>
