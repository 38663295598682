<template>
  <div style="width:100%">
    <NoData class="ma-3 mb-5" v-for="n in 10" :key="n"></NoData>
  </div>
</template>

<style lang="scss" scoped></style>
<script>
import NoData from "@/board/views/components/main/post/NoData";
export default {
  components: { NoData }
};
</script>
